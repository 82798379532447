import React, { useMemo, useContext } from 'react'
import { useLocation, NavLink as Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { number } from '@/services/utils'

import { Typography, useMediaQuery } from '@material-ui/core'
import { WebMenuItem, StyledBadge, MobileMenuItem } from './styles'

import { DrawerContext } from '.'
import theme from '@/theme'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

export const ANIMATION_NAME = 'MuiBadge-pulse'
export const ANIMATION_DURATION = 2000

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function MenuItem({
  label,
  Icon,
  path,
  badgeContent,
  badgePosition,
  ...props
}) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { pathname } = useLocation()
  const drawer = useContext(DrawerContext)

  const active = !!pathname.match(path)
  const id = useMemo(() => `badge-notification-${number(1000)}`, [])

  const renderIcon = <Icon weight="fill" size={32} />

  if (isMobile && drawer) {
    return (
      <MobileMenuItem
        size="large"
        onClick={drawer.close}
        component={Link}
        to={path}
        className={clsx({ active })}
      >
        <Typography>{label}</Typography>
        {renderIcon}
      </MobileMenuItem>
    )
  }

  return (
    <WebMenuItem {...props} className={clsx('AppBar-MenuItem', { active })}>
      <Link to={path} activeStyle={{ pointerEvents: 'none' }}>
        <span className="MenuItem-label">{label}</span>
        {badgeContent ? (
          <StyledBadge
            id={id}
            badgeContent={badgeContent}
            overlap="rectangular"
            className={clsx({ active })}
            color="primary"
            {...badgePosition}
          >
            {renderIcon}
          </StyledBadge>
        ) : (
          renderIcon
        )}
      </Link>
    </WebMenuItem>
  )
}

const TranslationStringType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
])

MenuItem.propTypes = {
  label: TranslationStringType,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  path: PropTypes.string.isRequired,
  disabled: PropTypes.bool,

  badgeContent: PropTypes.number,
  badgePosition: PropTypes.exact({
    top: PropTypes.number,
    right: PropTypes.number,
    topactive: PropTypes.number,
    rightactive: PropTypes.number,
  }),
}

MenuItem.defaultProps = {
  badgePosition: {
    top: 3,
    right: 7,
    topactive: 5,
    rightactive: 8,
  },
}

export default MenuItem

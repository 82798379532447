import React from 'react'
import { Buildings, Users } from 'phosphor-react'

import { PATHS } from '@/consts/paths'
import { MenuItem } from '../MenuItem'
import { CommonMenuItems } from './Common'

export function DeveloperMenuItems() {
  return (
    <>
      <CommonMenuItems />

      <MenuItem
        Icon={Buildings}
        label="Empresas"
        path={PATHS.admin.companies}
      />

      <MenuItem Icon={Users} label="Usuários" path={PATHS.admin.users} />
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, useMediaQuery } from '@material-ui/core'

import theme from 'theme'
import * as medias from 'consts/medias'

import { useSortData, usePagination, useProfile } from 'hooks/index'
import { isNotInvited, isActive, applyFilters } from 'services/utils'
import { applyScopeInvite, applyScope } from 'services/scope'

import {
  getUsersRequest,
  removeUserRequest,
  showFormEdit,
  sendInviteRequest,
  logIsAsWebUserRequest,
} from 'store/modules/admin/users/reducer'

import { Box, Tooltip } from '@material-ui/core'
import { Table, Content, ChipStatus, TablePagination } from 'components/index'
import { ComboboxUserStatus, ComboboxInviteStatus } from 'components/Combobox'
import { inviteStatus as inviteStatusTypes } from 'consts/types'
import { Mail, Edit, Delete, Add, Signout } from 'components/Icons'
import { StyledAvatar } from './styles'

import { StyledButton, LoginButton, Paper } from '../Companies/styles'

import Filter from './Filter'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const initialSort = 'company.emp_razao_social'

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function ActiveChipStatus({ isActive }) {
  const color = theme.palette[isActive ? 'success' : 'error'].main

  return (
    <ChipStatus label={isActive ? 'Ativo' : 'Inativo'} background={color} />
  )
}

function AccessEmailButton({ email, ...props }) {
  return (
    <Box display="flex" alignItems="center">
      <span>{email}</span>
      <Tooltip title={`Acessar como ${email}`}>
        <LoginButton component={Signout} paddingLeft={1} {...props} />
      </Tooltip>
    </Box>
  )
}

function SendInviteButton({ inviteStatus, ...props }) {
  const title = isNotInvited(inviteStatus)
    ? 'Enviar Convite'
    : 'Reenviar Convite'

  return (
    <IconButton size="small" {...props}>
      <Mail className={inviteStatus} fontSize="small" title={title} />
    </IconButton>
  )
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

function AdminUsersPage() {
  const sm = useMediaQuery(medias.sm)
  const dispatch = useDispatch()

  const profile = useProfile()
  const loading = useSelector((state) => state.admin.users.loading)
  const webUsers = useSelector((state) => state.admin.users.webUsers)

  const [status, setStatus] = useState('')
  const [inviteStatus, setInviteStatus] = useState(inviteStatusTypes.ALL)
  const [selectedFilters, setSelectedFilters] = useState([])

  useEffect(() => {
    dispatch(getUsersRequest())
  }, [dispatch])

  const { currentSort, onSortChange, sortData } = useSortData({
    initialField: initialSort,
  })

  const {
    onChangePage,
    onChangeRowsPerPage,
    calculateNewPaginatorData,
    rowsPerPage,
    page,
  } = usePagination()

  const isAdmin = profile.isMasterAdmin

  // --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

  const handleMailLogin = (webUser) => dispatch(logIsAsWebUserRequest(webUser))

  const handleSendInvite = (user) => dispatch(sendInviteRequest(user))

  const handleDelete = (webUser) => dispatch(removeUserRequest(webUser))

  const handleDisplayUserForm = (webUser) =>
    dispatch(showFormEdit(webUser.id ? webUser : null))

  const handleChangeScope = (scopeSetter) => (e) => {
    const newScopeValue = e.target.value
    scopeSetter(newScopeValue)
    setSelectedFilters([])
  }

  function renderUserEmail({ cellValue, rowData: webUser }) {
    if (!isAdmin) return cellValue
    return (
      <AccessEmailButton
        email={cellValue}
        onClick={() => handleMailLogin(webUser)}
      />
    )
  }

  function renderSendInvite({ rowData: webUser }) {
    if (!webUser) return null
    return (
      <SendInviteButton
        inviteStatus={webUser.invite_status}
        onClick={() => handleSendInvite(webUser)}
      />
    )
  }

  function renderEdit({ rowData: webUser }) {
    return (
      <IconButton size="small" onClick={() => handleDisplayUserForm(webUser)}>
        <Edit />
      </IconButton>
    )
  }

  function renderDelete({ rowData: webUser }) {
    // if (isAdmin) return null

    const disabled =
      profile.web_user.weu_id === webUser.id ||
      webUser.invite_status !== 'not_invited'

    return (
      <IconButton
        size="small"
        disabled={disabled}
        onClick={() => handleDelete(webUser)}
      >
        <Delete />
      </IconButton>
    )
  }

  const applyScopes = (collection) =>
    applyScope(
      status,
      (user) => user.weu_ativo,
      applyScopeInvite(inviteStatus, (user) => user.invite_status, collection)
    )

  const filteredWebUsers = applyScopes(applyFilters(selectedFilters, webUsers))

  const { currentPageRecords } = calculateNewPaginatorData(
    sortData(filteredWebUsers)
  )

  const showNewUserButton = !isAdmin

  return (
    <Content
      title="Usuários"
      loading={loading}
      Controls={
        <>
          <ComboboxUserStatus
            value={status}
            onChange={handleChangeScope(setStatus)}
          />
          <ComboboxInviteStatus
            value={inviteStatus}
            onChange={handleChangeScope(setInviteStatus)}
          />
          {!sm && showNewUserButton && (
            <StyledButton
              disabled={isAdmin}
              color="primary"
              label="Adicionar"
              onClick={handleDisplayUserForm}
              endIcon={<Add />}
            />
          )}
        </>
      }
      SideComponent={
        <Filter
          data={filteredWebUsers}
          loading={loading}
          onFiltering={setSelectedFilters}
          selectedFilters={selectedFilters}
        />
      }
    >
      <Paper>
        <Table
          size="medium"
          currentSort={currentSort}
          onSortChange={onSortChange}
          data={currentPageRecords}
          cellStyle={({ columnName }) =>
            columnName === 'weu_avatar'
              ? {
                  maxWidth: '50px',
                }
              : {}
          }
          columns={[
            {
              field: 'weu_avatar',
              renderCell: ({ rowData: webUser }) => (
                <StyledAvatar alt={webUser.weu_nome} src={webUser.weu_avatar} />
              ),
            },
            { title: 'USUÁRIO', field: 'weu_nome' },
            {
              title: 'EMPRESA',
              field: 'emp_razao_social',
            },
            {
              title: 'EMAIL',
              field: 'weu_email',
              renderCell: renderUserEmail,
            },
            {
              title: 'CPF/CNPJ',
              field: 'weu_cpf',
              cellFormat: 'document',
            },
            { title: 'TELEFONE', field: 'weu_telefone' },
            {
              role: 'button',
              renderCell: renderSendInvite,
            },
            { role: 'button', renderCell: renderEdit },
            { role: 'button', renderCell: renderDelete },

            {
              title: 'STATUS',
              field: 'weu_ativo',
              role: 'status',
              renderCell: ({ cellValue: status }) => (
                <ActiveChipStatus isActive={isActive(status)} />
              ),
            },
          ]}
        />
        <TablePagination
          count={filteredWebUsers.length}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </Paper>
    </Content>
  )
}

export default AdminUsersPage

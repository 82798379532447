import styled from 'styled-components'
import { Badge, Button, IconButton } from '@material-ui/core'

import theme, { colors } from '@/theme'
import * as medias from '@/consts/medias'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const StyledButton = styled(IconButton)`
  .MuiSvgIcon-root {
    color: ${colors.white};
  }
`

// -----------------------------------------------------

export const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    background-color: ${theme.palette.error.main};
  }

  @media ${medias.md} {
    .MuiBadge-badge {
      top: 0;
      right: 0;

      height: unset;
      border-radius: 50%;
    }

    &.active .MuiBadge-badge {
      top: 0;
      right: 0;
    }
  }

  @media ${medias.sm} {
    .MuiBadge-pulse {
      animation: none;
    }
  }
`

export const WebMenuItem = styled.div`
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  > a {
    color: ${colors.color500};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  svg {
    color: ${colors.color100};
    /* font-size: 2rem; */
  }

  .MenuItem-label {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    border-bottom: 4px solid transparent;
    width: 100%;
    max-width: 40px;
    margin-top: 20px;
  }

  &[disabled] {
    user-select: none;
    pointer-events: none;

    > a {
      color: ${(props) => props.theme.palette.action.disabled};
    }
  }

  &.active {
    &::after {
      border-bottom-color: ${colors.color500};
    }
  }

  &.active,
  &:hover {
    .MenuItem-label {
      font-weight: bold;
      color: ${colors.color700};
    }

    svg {
      color: ${colors.white};
    }
  }

  @media ${medias.md} {
    margin-right: 0;

    .MenuItem-label {
      display: none;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
    }

    > a {
      padding: 6px 12px;
      color: ${colors.white};

      border-bottom: 1px solid ${colors.color600};

      .MuiSvgIcon-root {
        font-size: 2rem;
      }
    }

    &::after {
      display: none;
    }

    &.active,
    &:hover {
      > a {
        background-color: ${colors.color600};
        text-decoration: none;
        .MuiSvgIcon-root {
          font-size: 2rem;
        }
        .MenuItem-label {
          color: ${colors.white};
        }
      }
    }
  }
`

export const MobileMenuItem = styled(Button)`
  color: ${theme.palette.primary.contrastText};
  border-radius: 0;

  display: flex;
  justify-content: space-between;

  &.active {
    background-color: ${theme.palette.primary.dark};
  }

  .MuiButton-label {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.75rem;

    svg {
      font-size: 1.25rem;
    }
  }
`

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  vehicles: [],
  loading: true,
}

const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    getVehiclesRequest(state) {
      state.loading = true
    },
    getVehiclesReply(state, { payload }) {
      state.loading = false

      if (payload) {
        state.vehicles = payload.vehicles
      }
    },
  },
})

export const { getVehiclesRequest, getVehiclesReply } = vehiclesSlice.actions

export default vehiclesSlice.reducer

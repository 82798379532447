import axios from 'axios'
import { toast } from 'react-toastify'
import { downloadFile } from '@/services/utils'

const CONSULTA_DANFE_URL = process.env.REACT_APP_CONSULTA_DANFE_URL
const CONSULTA_DANFE_USERNAME = process.env.REACT_APP_CONSULTA_DANFE_USERNAME
const CONSULTA_DANFE_PASSWORD = process.env.REACT_APP_CONSULTA_DANFE_PASSWORD

const EXT = {
  PDF: 'pdf',
  XML: 'xml',
}

// -------------------------------------------------------------------

const api = axios.create({
  baseURL: CONSULTA_DANFE_URL,
})

const apiEndPoints = {
  danfe: '/danfe',
  xml: '/xml',
}

// -------------------------------------------------------------------

export async function getInvoiceXml(invoiceId) {
  try {
    const URL = `${apiEndPoints.xml}/${invoiceId}`

    const response = await api.get(URL, {
      auth: {
        username: CONSULTA_DANFE_USERNAME,
        password: CONSULTA_DANFE_PASSWORD,
      },
    })

    /**
     * @type {( { xml: string; chave: string;} )}
     */
    const content = response.data.XML[0]
    downloadFile(content, EXT.XML, 'xml', 'chave')

    toast.success('Download concluído')
  } catch (error) {
    console.log(error)
    toast.error('Falha ao baixar o XML. Tente novamente mais tarde')
  }
}

export async function getInvoiceDanfe(invoiceId) {
  try {
    const URL = `${apiEndPoints.danfe}/${invoiceId}`

    const response = await api.get(URL, {
      auth: {
        username: CONSULTA_DANFE_USERNAME,
        password: CONSULTA_DANFE_PASSWORD,
      },
    })

    /**
     * @type {( { pdf: string; chave: string;} )}
     */
    const content = response.data.Danfe[0]
    downloadFile(content, EXT.PDF, 'pdf', 'chave')

    toast.success('Download concluído')
  } catch (error) {
    console.log(error)
    toast.error('Falha ao baixar o DANFE. Tente novamente mais tarde')
  }
}

import { all, takeLatest, call, put } from 'redux-saga/effects'

import api from '@/services/api'
import handleErrors from '@/services/handleErrors'
import apiEndPoints from '@/consts/apiEndPoints'

import {
  getPossibleDatesRequest,
  getPossibleDatesFailure,
  getPossibleDatesSuccess,
} from './reducer'

function* getPossibleDates() {
  try {
    const response = yield call(
      api.get,
      apiEndPoints.miscellaneous.possibleDates
    )

    const {
      // min_date: minDate,
      // original_min_date: originalMinDate,
      message,
      dates,
    } = response.data

    yield put(getPossibleDatesSuccess({ message, dates }))
  } catch (error) {
    yield put(getPossibleDatesFailure())
    handleErrors(error, 'Não foi possível buscar as datas disponíveis')
  }
}

export default all([takeLatest(getPossibleDatesRequest.type, getPossibleDates)])

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  cancelEditCollect,
  updateCollectRequest,
} from 'store/modules/users/collections/reducer'

import { Content, Paper } from 'components/index'
import CollectPrint from 'components/Transportadora/CollectPrint'
import FormCollect from '../Collect/Form'
import { ComboboxCollectStatus } from '@/components/Combobox/CollectStatus'

import { IssuedCollectsFilter } from './Filter'
import { IssuedCollectsTable } from './Table'
import { StyledDialog } from './styles'

export default function UserIssuedCollectionsPage() {
  const [selectedFilters, setSelectedFilters] = useState([])
  const [collectForPrint, setCollectForPrint] = useState(null)
  const [collectStatus, setCollectStatus] = useState('')

  const dispatch = useDispatch()

  const isEditing = useSelector(
    (state) => state.users.collections.active.collectParams.isEditing
  )

  function handleSubmitCollectEdit(values) {
    dispatch(updateCollectRequest(values))
  }

  function handleCancelCollectEdit() {
    dispatch(cancelEditCollect())
  }

  const handleChangeScope = (scopeStter) => (event) => {
    const scopeValue = event.target.value
    scopeStter(scopeValue)
  }

  return (
    <Content
      title="Emitidas"
      SideComponent={
        <IssuedCollectsFilter
          onFiltering={setSelectedFilters}
          selectedFilters={selectedFilters}
        />
      }
      Controls={
        <ComboboxCollectStatus
          style={{ minWidth: '350px' }}
          value={collectStatus}
          onChange={handleChangeScope(setCollectStatus)}
        />
      }
    >
      <Paper className="border-none">
        {/* Table Collects */}
        <IssuedCollectsTable
          collectStatus={collectStatus}
          onPrintCollect={setCollectForPrint}
          requestFilters={selectedFilters}
        />

        {/* Edit Collect Form */}
        <StyledDialog
          open={Boolean(isEditing)}
          onClose={handleCancelCollectEdit}
        >
          <FormCollect isEditing onSubmit={handleSubmitCollectEdit} />
        </StyledDialog>
      </Paper>

      {collectForPrint && (
        <CollectPrint
          collect={collectForPrint}
          open={Boolean(collectForPrint)}
          onClose={() => setCollectForPrint(null)}
        />
      )}
    </Content>
  )
}

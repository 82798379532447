import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

const persistor = (reducers) => {
  const persistedReducer = persistReducer(
    {
      key: '@EmbramacoPortalTransportadorPersist',
      storage,
      whitelist: ['auth', 'user'],
      stateReconciler: autoMergeLevel2,
    },
    reducers
  )

  return persistedReducer
}

export default persistor

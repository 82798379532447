import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as medias from 'consts/medias'
import history from 'services/history'
import { Back } from '../Icons'
import { Tooltip, useMediaQuery } from '@material-ui/core'
import { Cancel, MoreVert, Search, Close } from '@material-ui/icons'
import Paper from '../Paper'
import SplashScreen from '../SplashScreen'
import {
  ContentTitle,
  Container,
  SideComponent,
  HeaderComponent,
  CenterComponent,
  ShowSidebarMobile,
} from './styles'

export function Content(props) {
  const sm = useMediaQuery(medias.sm)
  const [showSideBar, setShowSideBar] = useState(props.sideBarStart)

  const handleOpenSideBar = () => setShowSideBar((open) => !open)
  const hasSideComponent = Boolean(props.SideComponent)
  const withTitle = Boolean(props.title)

  const showSideButton = props.sideBarOpen ? false : hasSideComponent
  const showSideButtonDesktop = showSideButton && !sm
  const showSideButtonMobile = showSideButton && sm

  const containerClasses = clsx(props.className, {
    withTitle,
    withHeader: Boolean(props.HeaderComponent),
    loading: props.loading,
  })

  return (
    <Container id={props.containerId} className={containerClasses}>
      {showSideButtonMobile && (
        <ShowSidebarMobile
          color="primary"
          size="small"
          onClick={handleOpenSideBar}
        >
          {showSideBar ? <Close /> : <Search />}
        </ShowSidebarMobile>
      )}
      {withTitle && (
        <ContentTitle>
          <div className="ContentTitle-label">
            <div className="label">
              {props.showReturn && (
                <Back onClick={() => history.goBack()} title="Voltar" />
              )}
              <span title={props.title}>{props.title}</span>
            </div>
          </div>
          <div className="ContentTitle-controls">{props.Controls}</div>
        </ContentTitle>
      )}
      <section>
        {hasSideComponent && (
          <SideComponent
            className="SideComponent"
            direction="right"
            in={showSideBar}
            mountOnEnter
            unmountOnExit
          >
            <div>
              {showSideButtonDesktop && (
                <Tooltip title="Fechar menu lateral" arrow>
                  <div
                    className="SideComponent-CloseControl"
                    onClick={handleOpenSideBar}
                  >
                    <Cancel />
                  </div>
                </Tooltip>
              )}
              {props.SideComponent}
            </div>
          </SideComponent>
        )}
        <CenterComponent
          className="Content-CenterComponent"
          {...props.CenterComponentProps}
        >
          {showSideButtonDesktop && (
            <Tooltip title="Filtros" arrow placement="right">
              <div
                onClick={handleOpenSideBar}
                className={clsx('CenterComponent-SideBarControl', {
                  shift: hasSideComponent ? showSideBar : true,
                })}
              >
                <MoreVert />
              </div>
            </Tooltip>
          )}
          {props.HeaderComponent && (
            <HeaderComponent
              className="Content-HeaderComponent"
              {...props.HeaderComponentProps}
            >
              <Paper>{props.HeaderComponent}</Paper>
            </HeaderComponent>
          )}
          <div className="Content-Children">
            {props.loading && (
              <SplashScreen loadingText={props.loadingText} loaderSize={8} />
            )}
            {props.children}
          </div>
        </CenterComponent>
      </section>
    </Container>
  )
}

const ReactNodeType = PropTypes.element

Content.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  containerId: PropTypes.string,

  HeaderComponent: ReactNodeType,
  SideComponent: ReactNodeType,
  Controls: ReactNodeType,

  HeaderComponentProps: PropTypes.object,
  CenterComponentProps: PropTypes.object,

  showReturn: PropTypes.bool,
  sideBarStart: PropTypes.bool,
  sideBarOpen: PropTypes.bool,
}

Content.defaultProps = {
  showReturn: false,
  sideBarStart: false,
  sideBarOpen: false,
}

export default Content

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  data: {
    dashb_orders: [],
    dashb_loadings: [],
    dashb_shipments_count: [],
    dashb_shipments_sqmtr: [],
    dashb_quota: [],
  },
}

const dashboard = createSlice({
  name: 'carriersDashboard',
  initialState,
  reducers: {
    getDashboardRequest(state) {
      state.loading = true
    },
    getDashboardSuccess(state, { payload }) {
      state.loading = false
      state.data = payload
    },
    getDashboardFailure(state) {
      state.loading = false
    },
  },
})

export const { getDashboardFailure, getDashboardRequest, getDashboardSuccess } =
  dashboard.actions

export default dashboard.reducer

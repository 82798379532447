import PropTypes from 'prop-types'
import React, { useState, useEffect, useMemo, useRef } from 'react'

import { Button } from '@/components/Button'
import { Form, FormTextField } from '@/components/CoForm'
import {
  applyNumberMask,
  numberTextMaskProps,
  removeNumberMask,
} from '@/services/masks'
import { Yup as y } from '@/services/yup'
import { Box, Typography } from '@material-ui/core'

import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from '@/components/Dialog/styles'

const NewVehicleSchema = y.object({
  dsc_tipo_caminhao: y.string().required().min(6),
  dsc_caminhao: y.string().required().min(6),
  cod_tipo_caminhao: y.string().optional(),
  cam_pbt: y.string().required(),
})

export function VehicleFormEdit(props) {
  const { current, open, onClose, onSave } = props

  const [isSubmitting, setIsSubmitting] = useState(false)

  const formRef = useRef(null)

  const decimalMask = numberTextMaskProps()

  const defaultValues = useMemo(() => {
    return {
      dsc_tipo_caminhao: current?.dsc_tipo_caminhao || '',
      dsc_caminhao: current?.dsc_caminhao || '',
      cod_tipo_caminhao: current?.cod_tipo_caminhao || '',
      cam_pbt: applyNumberMask(String(current?.cam_pbt)) || '',
    }
  }, [current])

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setData(defaultValues)
    }
  }, [defaultValues])

  async function handleSubmit(data) {
    setIsSubmitting(true)

    await onSave({
      ...data,
      cam_pbt: removeNumberMask(data.cam_pbt),
    })

    setIsSubmitting(false)
  }

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Form
        onSubmit={handleSubmit}
        formRef={formRef}
        initialData={defaultValues}
        schema={NewVehicleSchema}
      >
        <StyledDialogTitle>
          {current ? 'Editar veículo' : 'Novo'}
        </StyledDialogTitle>

        <StyledDialogContent>
          <Box mt={2} display="flex" flexDirection="column" gridGap="1rem">
            <FormTextField
              fullWidth
              name="dsc_tipo_caminhao"
              label="Nome"
              type="text"
              variant="standard"
              placeholder="ex: CAV4EIXO + CARR4EIXO"
              required
            />

            <FormTextField
              fullWidth
              name="dsc_caminhao"
              label="Descrição"
              type="text"
              variant="standard"
              placeholder="ex: CAVALO 4EIXO CARRETA 4EIXO"
              required
            />

            <FormTextField
              fullWidth
              name="cod_tipo_caminhao"
              label="Código"
              type="text"
              variant="standard"
              placeholder="Classificação do veículo"
            />

            <FormTextField
              fullWidth
              name="cam_pbt"
              label="Tara"
              type="text"
              variant="filled"
              textMaskProps={decimalMask}
              inputProps={{
                inputMode: 'numeric',
              }}
              InputProps={{
                endAdornment: <Typography color="textSecondary">Kg</Typography>,
              }}
            />
          </Box>
        </StyledDialogContent>

        <StyledDialogActions>
          <Button variant="outlined" color="primary" onClick={onClose}>
            Cancelar
          </Button>

          <Button
            loading={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
          >
            Salvar
          </Button>
        </StyledDialogActions>
      </Form>
    </StyledDialog>
  )
}

VehicleFormEdit.propTypes = {
  current: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
}

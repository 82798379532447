import { equals } from 'ramda'

const environment = equals(process.env.REACT_APP_MODE || 'development')

export const environments = {
  PRODUCTION: environment('production'),
  DEVELOPMENT: environment('development'),
  TEST: environment('test'),
}

export default environments

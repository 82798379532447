import React, { useEffect, useState } from 'react'

import api from '@/services/api'
import apiEndPoints from '@/consts/apiEndPoints'
import handleErrors from '@/services/handleErrors'

import TextField from 'components/TextField'
import ComboboxBase from '../Base'


export function ComboboxCollectStatus(props) {
  const [options, setOptions] = useState([])
  
  useEffect(() => {
    const fetchCollectStatus = () => {
      api
        .get(apiEndPoints.miscellaneous.collectStatus)
        .then((response) => response.data)
        .then((status) => {
          const options = status.map((s) => ({
            ...s,
            value: s.id,
            label: s.descr.toUpperCase(),
          }))
          options.unshift({ label: 'TODOS', value: '' })
          setOptions(options)
        })
        .catch((err) =>
          handleErrors(
            err,
            'Não foi poosível buscar os status'
          )
        )
    }
    fetchCollectStatus()
  }, [])
  
  return (
    <ComboboxBase
      name="collect_status"
      options={options}
      renderInput={(provided) => (
        <TextField label="Status" variant="outlined" {...provided} />
      )}
      disableClearable
      {...props}
    />
  )
}

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { merge } from 'ramda'
import { useSelector } from 'react-redux'

import { cpf, placa } from '@/consts/regexp'
import theme, { colors } from '@/theme'
import formatter from '@/services/formatter'
import { Yup } from '@/services/yup'
import { toMask } from '@/services/masks'
import { FormTextField } from '@/components/CoForm'
import { DatePickerCollect } from '@/components/DatePicker/CollectDate'
import { ComboboxTruckTypes } from '@/components/Combobox'

import {
  FinishForm,
  Margin,
  StyledButton as SendButton,
  SendIcon,
} from '../styles'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const initialData = {
  vpt_placa: '',
  id_tipo_caminhao: '',
  vpt_motorista: '',
  vpt_cod_motorista: '',
  vpt_dcoleta: '',
  capacity: 0,
  available: 0,
  tare: 0,
}

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const schema = Yup.object().shape({
  vpt_placa: Yup.string().required('Informe a placa do veiculo'),
  id_tipo_caminhao: Yup.string().required('Informe o tipo do veiculo'),
  vpt_motorista: Yup.string().required('Informe o nome do motorista'),
  vpt_cod_motorista: Yup.string().required('Informe o CPF do motorista'),
  vpt_dcoleta: Yup.string().required('Informe a data de previsão'),
  // available: Yup.number().min(1, "Capacidade do veículo excedida."),
})

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

export function WeightField(props) {
  return (
    <FormTextField
      {...props}
      large
      disabled
      formatValue={(v) => formatter(v).toDecimal()}
    />
  )
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function FormCollect({ isEditing, onSubmit }) {
  const formRef = useRef(null)
  const availableFieldRef = useRef(null)
  const [loading] = useState(false)
  const [sendAvailable, setSendAvailable] = useState(true)
  const [truckType, setTruckType] = useState(null)

  const activeCollectWeight = useSelector(
    (state) => state.users.collections.active.weight
  )
  const activeCollectParams = useSelector(
    (state) => state.users.collections.active.collectParams
  )

  // const defaultLastVehicleOptions = activeCollectParams?.vpt_placa
  //   ? [{ value: activeCollectParams?.vpt_placa }]
  //   : []

  // const defaultLastDrivesOptions = activeCollectParams?.vpt_motorista
  //   ? [{ value: activeCollectParams?.vpt_motorista }]
  //   : []

  const updateCollectWeightFieldColor = (available) => {
    const collectWithOvercapacity = available < 1

    formRef.current.setFieldError(
      'available',
      collectWithOvercapacity ? 'Capacidade do veículo excedida.' : null
    )

    availableFieldRef.current.style.color = collectWithOvercapacity
      ? theme.palette.error.main
      : colors.color500
  }

  const calcAvailableWeight = (capacity, tare, weight) =>
    Number(capacity - tare - weight)

  const updateAvailableWeight = (capacity) => {
    const tare = formRef.current.getFieldValue('tare')

    setSendAvailable(true)
    const availableWeight = calcAvailableWeight(
      capacity,
      tare,
      activeCollectWeight
    )

    formRef.current.setData({
      capacity,
      available: truckType ? availableWeight : 0,
    })

    truckType ? updateCollectWeightFieldColor(availableWeight) : null

    if (availableWeight < 0) setSendAvailable(false)
  }

  useEffect(() => {
    const capacity = formRef.current.getFieldValue('capacity')
    updateAvailableWeight(capacity)
  }, [activeCollectWeight, truckType])

  function handleSubmit(values) {
    onSubmit(
      merge(values, {
        collectWeight: activeCollectWeight,
      })
    )
  }

  function handleAfterChangeTruckTypes(_, truckType) {
    setTruckType(truckType)
    const capacity = Number(truckType ? truckType.cam_pbt : 0)
    updateAvailableWeight(capacity)
  }

  // function handleChangeDriver(_, driver) {
  //   formRef.current.setFieldValue(
  //     'vpt_cod_motorista',
  //     driver ? driver.id : null
  //   )
  // }

  return (
    <FinishForm
      formRef={formRef}
      initialData={merge(initialData, activeCollectParams)}
      onSubmit={handleSubmit}
      schema={schema}
    >
      <span className="title">
        {isEditing ? 'Editar Coleta' : 'Finalizar Coleta'}
      </span>

      <FormTextField
        label="Placa"
        name="vpt_placa"
        uppercase
        textMaskProps={toMask(placa.array)}
        // defaultOptions={defaultLastVehicleOptions}
      />

      <ComboboxTruckTypes
        label="Tipo do Caminhão"
        name="id_tipo_caminhao"
        onAfterChange={handleAfterChangeTruckTypes}
        loading={loading}
      />

      <FormTextField
        label="Motorista"
        name="vpt_motorista"
        uppercase
        // onAfterChange={handleChangeDriver}
        // defaultOptions={defaultLastDrivesOptions}
      />

      <FormTextField
        label="CPF do Motorista"
        name="vpt_cod_motorista"
        textMaskProps={toMask(cpf.array)}
      />

      <DatePickerCollect label="Data Prevista para Coleta" name="vpt_dcoleta" />

      <FormTextField
        disabled
        label="Observação"
        name="vpt_obs"
        multiline
        maxRows={10}
        minRows={5}
      />

      <Margin />

      <WeightField label="Capacidade Total do Veículo" name="capacity" />

      <WeightField label="Tara Estimada" name="tare" />

      <WeightField
        label="Peso total da Coleta"
        name="total"
        value={activeCollectWeight}
      />

      <WeightField
        label="Disponível para Carregamento"
        name="available"
        inputRef={availableFieldRef}
      />

      <Margin />

      <SendButton
        type="submit"
        label={isEditing ? 'Salvar Alterações' : 'Enviar Coleta'}
        endIcon={<SendIcon />}
        disabled={!sendAvailable}
      />
    </FinishForm>
  )
}

FormCollect.propTypes = {
  onSubmit: PropTypes.func,
  isEditing: PropTypes.bool,
}

FormCollect.defaultProps = {
  isEditing: false,
}

export default FormCollect

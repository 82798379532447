/* eslint-disable react/no-children-prop */
import React, { useState, useEffect, useCallback } from 'react'

import api from '@/services/api'
import apiEndPoints from '@/consts/apiEndPoints'
import handleErrors from '@/services/handleErrors'

import { TabNavigation, TabPanel, Tabs } from '@/components'
import { Box, Container } from '@material-ui/core'
import { SettingsPaper } from './styles'

import { AvailableDates } from './available-dates'
import { DailyLimits } from './daily-limits'
import { toast } from 'react-toastify'

// -----------------------------------------------

const SETTINGS_TABS = [
  { value: 0, label: 'Datas Disponíveis' },
  { value: 1, label: 'Limites diários' },
]

// -----------------------------------------------

export default function AdminSettingsPage() {
  const [dailyLimits, setDailyLimits] = useState(null)

  const updateDailyLimits = useCallback(() => {
    api
      .get(apiEndPoints.admin.dailyLimits)
      .then((response) => response.data.daily_limits)
      .then(setDailyLimits)
      .catch(handleErrors)
  }, [])

  useEffect(() => {
    updateDailyLimits()
  }, [updateDailyLimits])

  const updateWebControl = useCallback(async (data) => {
    try {
      await api.put(apiEndPoints.admin.webControl, {
        web_control: { ...data },
      })

      updateDailyLimits()

      toast.success('Parâmetros atualizados')
    } catch (error) {
      handleErrors(error)
    }
  }, [])

  return (
    <TabNavigation>
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          gridGap="0.5rem"
          paddingTop="2rem"
        >
          <Tabs tabs={SETTINGS_TABS} />

          <SettingsPaper>
            <TabPanel index={0} children={<AvailableDates />} />
            <TabPanel
              index={1}
              children={
                <DailyLimits
                  dailyLimits={dailyLimits}
                  onUpdate={updateWebControl}
                />
              }
            />
          </SettingsPaper>
        </Box>
      </Container>
    </TabNavigation>
  )
}

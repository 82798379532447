import React from 'react'

import { PATHS } from '@/consts/paths'
import { isAdmin } from '@/services/utils'

import UsersHomePage from '@/pages/Users/Home'
import AdminCompaniesPage from '@/pages/Admin/Companies'
import AdminSettingsPage from '@/pages/Admin/Settings'
import AdminUsersPage from '@/pages/Admin/Users'
import AdminVehiclesPage from '@/pages/Admin/Vehicles'

import Route from './Route'

export function AdminRoutes({ companyType }) {
  if (isAdmin(companyType)) {
    return (
      <>
        <Route
          path={PATHS.admin.home}
          component={UsersHomePage}
          isPrivate
          exact
        />

        <Route
          path={PATHS.admin.companies}
          component={AdminCompaniesPage}
          isPrivate
          exact
        />

        <Route
          path={PATHS.admin.settings}
          component={AdminSettingsPage}
          isPrivate
          exact
        />

        <Route
          path={PATHS.admin.users}
          component={AdminUsersPage}
          isPrivate
          exact
        />

        <Route
          path={PATHS.admin.vehicles}
          component={AdminVehiclesPage}
          isPrivate
          exact
        />
      </>
    )
  }

  return (
    <>
      <Route
        path={PATHS.admin.users}
        component={AdminUsersPage}
        isPrivate
        exact
      />
    </>
  )
}

export default AdminRoutes

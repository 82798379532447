import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EnvelopeSimple, House, List, Note, Truck, Users } from 'phosphor-react'

import { translate } from '@/_i18n'
import { PATHS } from '@/consts/paths'
import { collectCountRequest } from 'store/modules/users/collections/reducer'

import { useProfile } from '@/hooks'
import { MenuItem } from '../MenuItem'
import { CommonMenuItems } from './Common'

export function CarrierMenuItems() {
  const profile = useProfile()

  const dispatch = useDispatch()

  const collectCount = useSelector((state) => state.users.collections.count)
  const collect = useSelector((state) => state.users.collections.active.collect)

  useEffect(() => {
    dispatch(collectCountRequest())
  }, [dispatch, collect])

  return (
    <>
      <MenuItem
        Icon={House}
        label={translate('toolbar.menus.home')}
        path={PATHS.user.home}
      />

      <MenuItem
        Icon={List}
        label={translate('toolbar.menus.orders')}
        path={PATHS.user.ordersForCollect}
      />

      <MenuItem
        Icon={Truck}
        label={translate('toolbar.menus.collect')}
        path={PATHS.user.collect}
        badgeContent={collectCount}
      />

      <MenuItem
        Icon={EnvelopeSimple}
        label={translate('toolbar.menus.issued')}
        path={PATHS.user.issueds}
      />

      <MenuItem
        Icon={Note}
        label={translate('toolbar.menus.queries')}
        path={PATHS.user.queries.root}
      />

      <CommonMenuItems />

      {profile.isAdminUser && (
        <MenuItem Icon={Users} label="Usuários" path={PATHS.admin.users} />
      )}
    </>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, LinearProgress, Typography } from '@material-ui/core'

import { formatter, parseDay } from '@/services/formatter'

const StyledLinearProggress = styled(LinearProgress)`
  height: 7px;
  border-radius: 8px;
`

export function DailyQuotaProggressBar(props) {
  const { day, amount, percent } = props

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Typography variant="body2" color="textSecondary">
          {parseDay(day)}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          {formatter(amount).toDecimal()}
        </Typography>
      </Box>

      <StyledLinearProggress
        value={percent}
        color="primary"
        variant="determinate"
        title={`${percent}%`}
      />
    </Box>
  )
}

DailyQuotaProggressBar.propTypes = {
  day: PropTypes.string,
  amount: PropTypes.string,
  percent: PropTypes.string,
}

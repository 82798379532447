import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { signOutRequest } from '@/store/modules/auth/reducer'

import eventEmitter from '@/services/eventEmitter'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const events = {
  signout: '@portalEcosis/SIGNOUTCHECKER/signout',
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

function SignOutChecker() {
  const dispatch = useDispatch()

  useEffect(() => {
    function handleSignOut() {
      dispatch(signOutRequest())
    }

    eventEmitter.on(events.signout, handleSignOut)

    return () => {
      eventEmitter.removeEventListener(events.signout, handleSignOut)
    }
  }, [dispatch])


  return null
}

export { SignOutChecker, events }
export default SignOutChecker

import React, { useRef } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Trash } from '../Icons'
import Button from '../Button'
import { Form, FormTextField } from '../CoForm'
import { Title, Divider } from '../Ranking/styles'
import { Container, Fields, ButtonArea } from './styles'
import theme from 'theme'

function SearchFilter(props) {
  const formSearchRef = useRef(null)

  const {
    title,
    fields,
    buttonLabel,
    inline,
    initialData,
    onSubmit,
    onClear,
    ...otherProps
  } = props

  return (
    <Container {...otherProps}>
      <Title>
        <span>{title}</span>
        <Divider />
      </Title>
      <Form
        formRef={formSearchRef}
        initialData={initialData}
        onSubmit={onSubmit}
      >
        <Fields className={clsx({ inline })}>
          {fields.map(
            (
              { name, label, Component, gutterBottom, ...otherProps },
              index
            ) => {
              const props = {
                label,
                name,
                fullWidth: true,
                ...otherProps,
              }

              return (
                <React.Fragment key={index}>
                  {Component ? (
                    <Component {...props} />
                  ) : (
                    <FormTextField {...props} />
                  )}
                  {gutterBottom && (
                    <Divider
                      style={{
                        marginTop: '0.5rem',
                      }}
                    />
                  )}
                </React.Fragment>
              )
            }
          )}
        </Fields>

        <ButtonArea>
          {onClear && (
            <Button
              onClick={() => {
                formSearchRef.current.reset()
                onClear()
              }}
              type="button"
              color="primary"
              variant="outlined"
              label="Limpar"
              size="small"
              startIcon={<Trash fill={theme.palette.primary.main} />}
            />
          )}
          <Button
            disabled={!onSubmit}
            label={buttonLabel}
            variant="contained"
            color="primary"
            size="small"
            type="submit"
          />
        </ButtonArea>
      </Form>
    </Container>
  )
}

const TranslationStringType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
])

SearchFilter.propTypes = {
  title: TranslationStringType,
  initialData: PropTypes.object,
  onSubmit: PropTypes.func,

  buttonLabel: TranslationStringType,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: TranslationStringType,
      Component: PropTypes.func,
    }).isRequired
  ),
}

SearchFilter.defaultProps = {
  buttonLabel: 'Buscar',
  fields: [],
  inline: true,
}

export { SearchFilter }
export default SearchFilter

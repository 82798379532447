import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { useSelector, useDispatch } from 'react-redux'

import { getPossibleDatesRequest } from '@/store/modules/comuns/possibleDates/reducer'
import { findMaxDate, dateNotInDates } from '@/services/utils'

import { FormDatePicker } from '@/components/CoForm'
import { Alert } from '@material-ui/lab'

// ------------------------------------------------------
export function DatePickerCollect(props) {
  const dispatch = useDispatch()

  const possibleDates = useSelector((state) => state.comuns.possibleDates.dates)
  const collectCount = useSelector((state) => state.users.collections.count)

  useEffect(() => {
    dispatch(getPossibleDatesRequest())
  }, [dispatch, collectCount])

  const shouldDisableDate = useCallback(
    (date) =>
      isEmpty(possibleDates) ? false : dateNotInDates(date, possibleDates),
    [possibleDates]
  )

  const disabledPicker = isEmpty(possibleDates)

  return (
    <>
      <CollectDateAlert />

      <FormDatePicker
        disabled={disabledPicker}
        disableToolbar
        disablePast
        variant="inline"
        shouldDisableDate={shouldDisableDate}
        maxDate={findMaxDate(possibleDates)}
        {...props}
      />
    </>
  )
}

DatePickerCollect.propTypes = {
  type: PropTypes.oneOf(['collect', 'schedule']),
}

DatePickerCollect.defaultProps = {
  type: 'collect',
}

// ------------------------------------------------------

function CollectDateAlert() {
  const possibleDateMessage = useSelector(
    (state) => state.comuns.possibleDates.message
  )
  // const minDate = useSelector((state) => state.comuns.possibleDates.minDate)

  // const originalMinDate = useSelector(
  //   (state) => state.comuns.possibleDates.originalMinDate
  // )

  // const displayDate = (date) => format(new Date(`${date} 00:00`), "dd 'de' MMM")

  // if (originalMinDate === minDate) return null

  if (!possibleDateMessage) return null

  return (
    <Alert severity="warning">
      <p
        dangerouslySetInnerHTML={{
          __html: possibleDateMessage,
        }}
      />
    </Alert>
  )
}

export default DatePickerCollect

import React from 'react'
import Route from './Route'
import { PATHS } from '@/consts/paths'

import UsersHomePage from '@/pages/Users/Home'
import CollectOrdersPage from '@/pages/Users/Collects/Orders'
import CollectPage from '@/pages/Users/Collects/Collect'
import IssuedCollectionsPage from '@/pages/Users/Collects/IssuedCollections'
import QueryShipmentsPage from '@/pages/Users/Queries/Shipments'
import QueryInvoicesPage from '@/pages/Users/Queries/Invoices'

export function CarrierRoutes() {
  return (
    <>
      <Route path={PATHS.user.home} component={UsersHomePage} isPrivate exact />
      <Route
        path={PATHS.user.ordersForCollect}
        component={CollectOrdersPage}
        isPrivate
        exact
      />
      <Route
        path={PATHS.user.collect}
        component={CollectPage}
        isPrivate
        exact
      />
      <Route
        path={PATHS.user.issueds}
        component={IssuedCollectionsPage}
        isPrivate
        exact
      />
      <Route
        path={PATHS.user.queries.invoices}
        component={QueryInvoicesPage}
        isPrivate
        exact
      />

      <Route
        path={PATHS.user.queries.shipments}
        component={QueryShipmentsPage}
        isPrivate
        exact
      />
    </>
  )
}

export default CarrierRoutes

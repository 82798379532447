import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { merge } from 'ramda'

import PATHS from 'consts/paths'
import apiEndPoints from 'consts/apiEndPoints'
import environments from 'consts/environments'
import { ACCEPT_INVITE, RECOVERY } from 'consts/actions'

import api from 'services/api'
import history from 'services/history'
import handleErrors from 'services/handleErrors'
import { Yup } from 'services/yup'
import { useQuery } from 'hooks'
import { persistor } from 'store/index'
import { signInRequest } from 'store/modules/auth/reducer'

import { FormTextField, FormCheckbox } from 'components/CoForm'
import { FormPasswordField } from 'components/PasswordField'
import {
  ComboBoxUserCompanies,
  comboBoxUserCompanies,
} from 'components/Combobox/UserCompanies'
import {
  PasswordControl,
  Container,
  StyledForm,
  SubmitButton,
  StyledLink,
} from '../styles'

import logoEmpresa from '../../../assets/images/logo-empresa.svg'
import { Box, Typography } from '@material-ui/core'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const formInitialData = {
  weu_nome: '',
  weu_email: '',
  password: '',
  confirmPassword: '',
  rememberMe: true,
  token: null,
  weu_id: null,
}

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const schema = Yup.object().shape({
  weu_email: Yup.string().email().required('Email deve ser informado'),
  company: Yup.string().when('token', (token, field) => {
    return !token
      ? field.required('Selecione uma empresa para continuar')
      : field.notRequired()
  }),
  password: Yup.string()
    .min(6, 'Senha deve ter no mínimo 6 caractéres')
    .required('Senha deve ser informada'),
  confirmPassword: Yup.string().when('token', (token, field) => {
    return token
      ? field
          .oneOf(
            [Yup.ref('password')],
            'Confirmação da senha difere da senha informada'
          )
          .required('Confirmação da senha deve ser informada')
      : field.nullable()
  }),
})

const getOperation = (token, queryOperation) => {
  if (token && queryOperation === null) return ACCEPT_INVITE
  else if (token && queryOperation === RECOVERY) return RECOVERY
  else return null
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function SigninPage() {
  const formRef = useRef(null)

  const dispatch = useDispatch()
  const loading = useSelector((state) => state.auth.loading)

  const { token } = useParams()
  const query = useQuery()

  const hasToken = Boolean(token)
  const operation = getOperation(token, query.get('operation'))

  const initialData = merge(formInitialData, { token, operation })

  useEffect(() => {
    async function handleURLToken() {
      if (token) {
        try {
          if (operation === RECOVERY) {
            const response = await api.get(
              apiEndPoints.passwords.onMember(token)
            )

            const user = response.data

            formRef.current.setData({
              weu_email: user.weu_email,
              weu_nome: user.weu_nome,
              id: user.id,
            })
          }

          if (operation === ACCEPT_INVITE) {
            const response = await api.get(apiEndPoints.invites.onMember(token))
            const invite = response.data

            formRef.current.setData({
              weu_email: invite.wec_email,
              weu_nome: invite.guest.weu_nome,
              id: invite.wec_id,
            })
          }
        } catch (error) {
          handleErrors(error)
          history.push(PATHS.signin.link)
        }
      } else {
        formRef.current.setValues(initialData)
      }
    }

    handleURLToken()
  }, [token, operation])

  function handleBlurEmail(e) {
    const email = e.target.value
    comboBoxUserCompanies.fetchUserCompanies(email)
  }

  function handleSubmit(values) {
    persistor.persist()
    if (!values.rememberMe) persistor.pause()
    dispatch(signInRequest({ ...values }))
  }

  function getPresentation() {
    switch (operation) {
      case 'recovery':
        return 'Confirme sua nova senha para continuar.'
      case 'accept':
        return 'Bem vindo, você foi convidado para fazer parte do portal, Preencha os dados abaixo para completar seu cadastro.'
      default:
        return 'Bem vindo novamente, preencha os dados abaixo para logar.'
    }
  }

  const presentation = getPresentation()

  return (
    <Container>
      <StyledForm
        formRef={formRef}
        schema={schema}
        initialData={initialData}
        onSubmit={handleSubmit}
      >
        <main>
          <header>
            <img src={logoEmpresa} alt="Embramaco - Cerâmica" />

            {environments.TEST ? (
              <Box
                bgcolor="red"
                padding="0.25rem 0.5rem"
                borderRadius="4px"
                color="white"
              >
                <Typography variant="h6">HOMOLOGAÇÃO</Typography>
              </Box>
            ) : (
              <span className="subtitle">{presentation}</span>
            )}
          </header>

          <div className="fields">
            {hasToken && (
              <FormTextField
                disabled={hasToken}
                name="weu_nome"
                label="Nome"
                type="text"
                variant="standard"
                size="medium"
              />
            )}
            <FormTextField
              autoFocus={!hasToken}
              disabled={hasToken}
              name="weu_email"
              label="E-mail"
              type="email"
              onBlur={handleBlurEmail}
              variant="standard"
              size="medium"
            />
            {!hasToken && (
              <ComboBoxUserCompanies autoFocus={hasToken} name="company" />
            )}
            <FormPasswordField
              name="password"
              label="Senha"
              autoComplete="current-password"
              variant="standard"
              size="medium"
            />
            {hasToken && (
              <FormPasswordField
                name="confirmPassword"
                label="Confirme sua senha"
                autoComplete="new-password"
                variant="standard"
                size="medium"
              />
            )}
            {environments.DEVELOPMENT && (
              <PasswordControl>
                <FormCheckbox
                  name="rememberMe"
                  label="Lembrar de mim"
                  size="small"
                  color="primary"
                />
              </PasswordControl>
            )}
            <SubmitButton
              color="primary"
              variant="contained"
              disableElevation
              type="submit"
              label="Entrar"
              loading={loading}
            />
            {!hasToken && (
              <StyledLink to={PATHS.reset}>Esqueceu a senha ?</StyledLink>
            )}
          </div>
        </main>
      </StyledForm>
    </Container>
  )
}

export default SigninPage

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { isEmpty } from 'ramda'

import {
  Button,
  Content,
  Table as CustomTable,
  TablePagination,
  confirmationDialog,
} from '@/components'
import { Box, IconButton } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { PencilSimple, Trash } from 'phosphor-react'

import apiEndPoints from '@/consts/apiEndPoints'
import { usePagination, useSortData } from '@/hooks'
import api from '@/services/api'
import handleErrors from '@/services/handleErrors'
import useListState from '@/hooks/useListState'
import { getVehiclesRequest } from '@/store/modules/admin/vehicles/reducer'

import { VehicleFormEdit } from './FormEdit'
import { VehiclesPaper } from './styles'

// ---------------------------------------------------

export default function AdminVehiclesPage() {
  const { vehicles, loading: vehiclesLoading } = useSelector(
    (state) => state.admin.vehicles
  )

  const dispatch = useDispatch()

  const vehiclesList = useListState(vehicles)

  useEffect(() => {
    dispatch(getVehiclesRequest())
  }, [dispatch])

  useEffect(() => {
    vehiclesList.setList(vehicles)
  }, [vehicles, vehiclesList.setList])

  const [currentVehicle, setCurrentVehicle] = useState(null)

  const [openVehicleDialog, setOpenVehicleDialog] = useState(false)

  const { currentSort, onSortChange, sortData } = useSortData({
    initialField: 'id',
    initialOrder: 'desc',
  })

  const {
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    calculateNewPaginatorData,
  } = usePagination({
    initialRowsPerpage: 10,
  })

  async function createVehicle(data) {
    try {
      const response = await api.post(apiEndPoints.vehicles.root, {
        truck_type: {
          dsc_tipo_caminhao: data.dsc_tipo_caminhao,
          dsc_caminhao: data.dsc_caminhao,
          cod_tipo_caminhao: data.cod_tipo_caminhao,
          cam_pbt: data.cam_pbt,
        },
      })

      const vehicle = response.data

      vehiclesList.addItem(vehicle)

      toggleOpenVehicleDialog()

      toast.success('Veículo salvo')
    } catch (error) {
      handleErrors(error, 'Não foi possível salvar o veículo')
    }
  }

  async function updateVehicle(vehicleId, data) {
    try {
      await api.put(apiEndPoints.vehicles.root.concat(`/${vehicleId}`), {
        truck_type: {
          dsc_tipo_caminhao: data.dsc_tipo_caminhao,
          dsc_caminhao: data.dsc_caminhao,
          cod_tipo_caminhao: data.cod_tipo_caminhao,
          cam_pbt: data.cam_pbt,
        },
      })

      vehiclesList.updateItem(vehicleId, data)

      toggleOpenVehicleDialog()

      toast.success('Veículo salvo')
    } catch (error) {
      handleErrors(error, 'Não foi possível atualizar o veículo')
    }
  }

  async function deleteVehicle(vehicleId) {
    const confirm = await confirmationDialog.open(
      'Confirmar',
      'Deseja remover o veículo ?'
    )

    if (confirm) {
      try {
        await api.delete(apiEndPoints.vehicles.root.concat(`/${vehicleId}`))

        vehiclesList.removeItem(vehicleId)

        toast.success('Veículo removido')
      } catch (error) {
        handleErrors(error, 'Não foi possível excluir o veículo')
      }
    }

    confirmationDialog.close()
  }

  async function saveVehicle(data) {
    if (currentVehicle) {
      await updateVehicle(currentVehicle.id, data)
    } else {
      await createVehicle(data)
    }
  }

  function toggleOpenVehicleDialog() {
    setOpenVehicleDialog(!openVehicleDialog)
  }

  function addVehicle() {
    setCurrentVehicle(null)

    toggleOpenVehicleDialog()
  }

  function editVehicle(vehicle) {
    setCurrentVehicle(vehicle)

    toggleOpenVehicleDialog()
  }

  function getTableActions({ rowData: vehicle }) {
    return (
      <Box display="flex" gridGap="1rem">
        <IconButton
          onClick={() => editVehicle(vehicle)}
          color="primary"
          size="small"
        >
          <PencilSimple />
        </IconButton>

        <IconButton
          disabled={!vehicle.can_remove}
          onClick={() => deleteVehicle(vehicle.id)}
          color="primary"
          size="small"
        >
          <Trash />
        </IconButton>
      </Box>
    )
  }

  const displayedRows = sortData(
    isEmpty(vehiclesList.list) ? vehicles : vehiclesList.list
  )

  const { currentPageRecords } = calculateNewPaginatorData(displayedRows)

  return (
    <Content
      title="Veículos"
      Controls={
        <Box>
          <Button
            onClick={addVehicle}
            startIcon={<Add />}
            color="primary"
            variant="contained"
          >
            Adicionar
          </Button>
        </Box>
      }
      SideComponent={null}
    >
      <VehiclesPaper>
        <CustomTable
          loading={vehiclesLoading}
          currentSort={currentSort}
          onSortChange={onSortChange}
          size="medium"
          columns={[
            { title: 'ID', field: 'id' },
            { title: 'TIPO', field: 'dsc_tipo_caminhao' },
            { title: 'DESCRIÇÃO', field: 'dsc_caminhao' },
            {
              title: 'TARA',
              field: 'cam_pbt',
              cellFormat: 'decimal',
              align: 'right',
            },
            { title: 'CÓDIGO', field: 'cod_tipo_caminhao' },
            //
            { role: 'button', renderCell: getTableActions },
          ]}
          data={currentPageRecords}
        />

        <TablePagination
          count={displayedRows.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </VehiclesPaper>

      <VehicleFormEdit
        current={currentVehicle}
        open={openVehicleDialog}
        onClose={toggleOpenVehicleDialog}
        onSave={saveVehicle}
      />
    </Content>
  )
}

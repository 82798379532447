import { parseISO } from 'date-fns'
import flatten from 'flat'
import {
  groupWith,
  groupBy,
  prop,
  sort,
  forEachObjIndexed,
  mapObjIndexed,
  anyPass,
  isNil,
  isEmpty,
  not,
  view,
  lensPath,
  split,
  any,
  all,
  equals,
  defaultTo,
  sum,
} from 'ramda'
import { company, inviteStatus } from 'consts/types'
import * as regexp from 'consts/regexp'

import api from 'services/api'
import formatter from 'services/formatter'
import handleErrors from 'services/handleErrors'
import apiEndPoints from 'consts/apiEndPoints'

import { orderBy } from 'lodash'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

export const filterProps = {
  ID: 'id',
  BY: 'filterBy',
  VALUE: 'value',
}

export const SPLITTER = '.'

// --------------- ℙ𝕣𝕖𝕕𝕚𝕔𝕒𝕥𝕖𝕤 ---------------

export const isDeveloper = (type) => equals(type, company.ECOSIS)

export const isAdmin = (type) =>
  any(equals(type), [company.ADMIN, company.ECOSIS])

export const isMasterAdmin = (companyType, userType) =>
  all(isAdmin, [companyType, userType])

export const isCarrier = (companyType) =>
  equals(companyType, company.TRANSPORTADORA)

export const isCustomer = (companyType) => equals(companyType, company.CLIENTE)

export const isEmail = (email, acceptUnicode = true) =>
  (acceptUnicode ? regexp.email.withUnicode : regexp.email.withoutUnicode).test(
    String(email).toLowerCase()
  )

export const isActive = (status) => status === 'active'

export const isNotInvited = (status) => equals(status, inviteStatus.NOT_INVITED)

export const isInvited = (status) => equals(status, inviteStatus.INVITED)

export const isAccepted = (status) => equals(status, inviteStatus.ACCEPTED)

export const isEmptyOrNil = anyPass([isNil, isEmpty])

// --------------- ℚ𝕦𝕖𝕣𝕚𝕖𝕤 ---------------

export const createSortClause = (currentSort) =>
  `${currentSort.field} ${currentSort.order.toUpperCase()}`

export const createQueryParameters = ({
  rowsPerPage,
  page,
  filters,
  currentSort,
  ...queryParameters
} = {}) => {
  const res = { ...queryParameters }

  if (filters !== undefined) {
    res.filters = filters
  }

  if (currentSort !== undefined) {
    res.ordination = createSortClause(currentSort)
  }

  if (page !== undefined) {
    res.limit = rowsPerPage
    res.offset = page * rowsPerPage
  }

  return res
}

// --------------- 𝔸ℙ𝕀 ---------------

export const fetchVehiclebyPlate = async (plate) => {
  try {
    const response = await api.get(apiEndPoints.miscellaneous.vehicle(plate))
    return response.data
  } catch (error) {
    // handleErrors(
    //   error,
    //   `Não foi possível buscar os dados do veículo, referentes a placa ${plate}`
    // );

    return null
  }
}

// --------------- 𝔻𝕒𝕥𝕖 ---------------

export const today = () => new Date().toLocaleDateString('pt-BR')

export const findMaxDate = (dates) =>
  parseISO(sort((a, b) => new Date(b) - new Date(a), dates)[0])

export const dateInDates = (date, dates) => {
  const fmtDate = new Date(date).toISOString().slice(0, 10)
  return dates.includes(fmtDate)
}

export const dateNotInDates = (date, dates) => not(dateInDates(date, dates))

// --------------- 𝕄𝕒𝕥𝕙 ---------------

export const number = (scale) => Math.floor(Math.random() * scale)

export const precisionRound = (number, precision) => {
  var factor = Math.pow(10, precision)
  return Math.round(number * factor) / factor
}

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

export const defaultToZero = defaultTo(0)

export const padZero = (pad, value) => {
  let _pad = [...Array(pad)].map(() => '0').join('')
  return (_pad + value.replace('/', '')).slice(-pad)
}

export const normalize = (v) => (v ? v.replace(/[()]/g, '') : v)

export const notEmpty = (value) => not(isEmpty(value))

export const isNotEmptyArray = (value) =>
  Array.isArray(value) && notEmpty(value)

export const groupWithField = (field, data) => {
  return groupWith(
    (a, b) => a[field] === b[field],
    [...data].sort((a, b) => a[field] - b[field])
  )
}

export const groupWithCustomer = (data, sortBy) => {
  const groups = groupWithField('cod_cliente', data)

  const groupsWithSorter = groups.map((group) => ({
    rows: group,
    sorter: group[0]?.[sortBy] || '',
  }))

  const customers = orderBy(groupsWithSorter, ['sorter'], ['asc']).map(
    (group) => group.rows
  )

  return customers
}

export const groupWithCompany = (data) => groupWithField('id_cia', data)

export const groupWithCarrier = (data) => {
  const group = groupWith(
    (a, b) => a.cod_transportadora === b.cod_transportadora
  )

  const sortedData = [...data].sort(
    (a, b) => b.cod_transportadora - a.cod_transportadora
  )
  return group(sortedData)
}

export const sample = (arr) => arr[number(arr.length)]

export async function downloadFileByUrl(url, ...provided) {
  try {
    const response = await api.get(url)
    const result = response.data
    downloadFile(result, ...provided)
  } catch (error) {
    handleErrors(error, 'Não foi possível completar o download')
  }
}

export function downloadFile(file, extension, fileProp, fileNameProp) {
  const a = document.createElement('a')
  a.href = `data:${extension};base64,${file[fileProp]}`
  a.download = [file[fileNameProp], extension].join('.')
  a.click()
}

export const extractLens = (lens, item) => {
  if (item) {
    const fieldLens = lensPath(split(SPLITTER, lens))
    return view(fieldLens, item)
  } else return (item) => extractLens(lens, item)
}

export const createGroupHeader = (label, start, expand) => ({
  label,
  start,
  expand,
})

export const createColumnField = (title, field, cellFormat, align) => ({
  title,
  field,
  cellFormat,
  align,
})

export const matchWith =
  (value = '', cb) =>
  (i) => {
    const iValue = cb(i)
    if (typeof iValue === 'string') {
      const lowerValue = value.toLowerCase()
      const lowerIValue = iValue.toLowerCase()

      const rgxp = new RegExp(lowerValue)
      const match = rgxp.test(lowerIValue)

      return match
    }

    return true
  }

export const formatDateFields = (filters, dateFields = []) =>
  mapObjIndexed((value, fieldName) => {
    if (dateFields.includes(fieldName)) return formatter(value).toSimpleDate()
    else return value
  }, flatten({ ...filters }))

// --------------- ℂ𝕣𝕖𝕒𝕥𝕖 𝔽𝕚𝕝𝕥𝕖𝕣𝕤 ---------------

/** Function responsible for analyzing whether it is necessary to search the value between the object's layers. */
export const hasLens = (value) =>
  typeof value === 'string' && value.indexOf(SPLITTER) !== -1

/** Function responsible for bringing the group key. ex: CORPORATE_NAME(groupKey): 22(groupValue) */
export const groupByAttribute = (attribute) => (dataItem) =>
  hasLens(attribute)
    ? extractLens(attribute, dataItem)
    : prop(attribute, dataItem)

/** Function responsible for measuring the amount of data belonging to each group. */
export const createDataRankingBy = (data, attribute) =>
  mapObjIndexed((r) => r.length, groupBy(groupByAttribute(attribute), data))

/** Function responsible for creating filtering rankings, separated by the attribute in the object and its occurrences in the collection. */
export const createFilters = (attributes, collection) => {
  if (!collection) return (data) => createFilters(attributes, data)

  const rankedFilters = attributes.reduce((acc, attribute) => {
    acc[attribute] = createDataRankingBy(collection, attribute)
    return acc
  }, {})

  return rankedFilters
}

// --------------- 𝔸𝕡𝕡𝕝𝕪 𝔽𝕚𝕝𝕥𝕖𝕣𝕤 ---------------

/** Function responsible for comparing the value received during the collection interaction with the values present in the filters. */

export const defaultComparator = (valueToCompare, values) =>
  values.some((value) =>
    new RegExp(normalize(value), 'i').test(normalize(valueToCompare))
  )

// export const defaultComparator = (valueToCompare, values) =>
//   findIndex(
//     (value) =>
//       new RegExp(removeParentheses(value), "i").test(
//         removeParentheses(valueToCompare)
//       ),
//     values
//   ) !== -1;

export const applyFilters = (
  filters,
  collection,
  comparator = defaultComparator
) => {
  if (isEmpty(filters)) return collection

  let result = []
  let iteration = 0

  const groupedFilters = groupBy(prop(filterProps.BY), [...filters])

  forEachObjIndexed((filters, attr) => {
    result = [...(iteration > 0 ? result : collection)].filter((item) =>
      comparator(
        extractLens(attr, item),
        filters.map((f) => f.value),
        attr
      )
    )
    iteration++
  }, groupedFilters)

  return result
}

// --------------- 𝔽𝕠𝕣𝕞𝕒𝕥 𝔽𝕚𝕝𝕥𝕖𝕣𝕤 ---------------

export const toRankingOptions = (options = {}) =>
  Object.entries(options).map(([label, value]) => ({
    label,
    value,
  }))

export const formatFilter = ([k, v]) => ({
  [filterProps.ID]: [k, v].join('-'),
  [filterProps.BY]: k,
  [filterProps.VALUE]: v,
})

export const formatFilters = (filters) =>
  Object.entries(flatten(filters))
    .map(formatFilter)
    .filter((f) => !!f[filterProps.VALUE])

export const updateActive =
  ({ row }, by = 'id') =>
  (c) =>
    c[by] === row[by] ? { ...c, active: !c.active } : c

export const sumOf = (arr, field) => sum(arr.map((i) => i[field]))

export const copyObject = (obj) => JSON.parse(JSON.stringify(obj))

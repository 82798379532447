import { all, takeLatest, call, put } from 'redux-saga/effects'
import { availableDatesActions } from './reducer'

import apiEndPoints from '@/consts/apiEndPoints'
import api from '@/services/api'
import handleErrors from '@/services/handleErrors'

function* getAvailableDates() {
  let availableDates = []

  try {
    const URL = apiEndPoints.availableDates.root

    const response = yield call(api.get, URL)
    availableDates = response.data
  } catch (error) {
    handleErrors(
      error,
      'Falha ao buscas as datas disponíveis. Tente novamente.'
    )
  } finally {
    yield put(availableDatesActions.getAvailableDatesReply({ availableDates }))
  }
}

function* createAvailableDate({ payload }) {
  const { data } = payload

  let newDate = null

  try {
    const URL = apiEndPoints.availableDates.root

    const response = yield call(api.post, URL, {
      available_date: {
        vdt_data: data.vdt_data,
        vdt_tipo: data.vdt_tipo,
      },
    })

    newDate = response.data
  } catch (error) {
    handleErrors(error, 'Falha ao salvar a data')
  } finally {
    yield put(availableDatesActions.createAvailableDateReply({ newDate }))
  }
}

function* updateAvailableDate({ payload }) {
  const { availableDateId, data } = payload

  let updatedDate = null

  try {
    const URL = apiEndPoints.availableDates.root.concat(`/${availableDateId}`)

    const response = yield call(api.put, URL, {
      available_date: {
        vdt_data: data.vdt_data,
        vdt_tipo: data.vdt_tipo,
      },
    })

    updatedDate = response.data
  } catch (error) {
    handleErrors(error, 'Falha ao salvar a data')
  } finally {
    yield put(availableDatesActions.updateAvailableDateReply({ updatedDate }))
  }
}

function* removeAvailableDate({ payload }) {
  const { availableDateId } = payload

  try {
    const URL = apiEndPoints.availableDates.root.concat(`/${availableDateId}`)

    yield call(api.delete, URL)
  } catch (error) {
    handleErrors(error, 'Falha ao remover a data')
  } finally {
    yield put(availableDatesActions.removeAvailableDateReply())
  }
}

export default all([
  takeLatest(
    availableDatesActions.getAvailableDatesRequest().type,
    getAvailableDates
  ),
  takeLatest(
    availableDatesActions.createAvailableDateRequest().type,
    createAvailableDate
  ),
  takeLatest(
    availableDatesActions.updateAvailableDateRequest().type,
    updateAvailableDate
  ),
  takeLatest(
    availableDatesActions.removeAvailableDateRequest().type,
    removeAvailableDate
  ),
])

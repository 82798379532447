import { conformToMask } from 'react-text-mask'
import { cnpj, cpf, telefoneCelular, telefoneFixo } from '../consts/regexp'
import { unmask } from '../services/masks'
import { format } from 'date-fns'

const LOCALE = 'pt-BR'

const formats = (value) => ({
  toDecimal: ({ decimalCases = 2, ...other } = {}) => {
    if (!value) return value
    return `${Number(value).toLocaleString(LOCALE, {
      minimumFractionDigits: decimalCases,
      maximumFractionDigits: decimalCases,
      ...other,
    })}`
  },
  toPercent: (float) => {
    return parseFloat(value * 100).toFixed(float) + '%'
  },
  toCNPJ: () => {
    switch (typeof value) {
      case 'string':
        return conformToMask(value, cnpj.array).conformedValue
      default:
        return value
    }
  },
  toCPF: () => {
    switch (typeof value) {
      case 'string':
        return conformToMask(value, cpf.array).conformedValue
      default:
        return value
    }
  },
  toCNPJorCPF: () => {
    switch (typeof value) {
      case 'string': {
        const mask = value.length === 11 ? cpf.array : cnpj.array
        return conformToMask(value, mask).conformedValue
      }
      default:
        return value
    }
  },
  toTelefone: () => {
    switch (typeof value) {
      case 'string': {
        if (unmask(value).length > 10)
          return conformToMask(value, telefoneCelular.array).conformedValue

        return conformToMask(value, telefoneFixo.array).conformedValue
      }
      default:
        return value
    }
  },
  toSimpleDate: () => {
    switch (typeof value) {
      case 'string':
        return value && new Date(value).toLocaleDateString(LOCALE)
      default:
        return value
    }
  },
  toDate: () => {
    switch (typeof value) {
      case 'string':
        return new Date(value).toLocaleDateString(LOCALE, {
          hour: '2-digit',
          minute: '2-digit',
        })
      default:
        return value
    }
  },
  toTimeDate: ({ ...options } = {}) => {
    switch (typeof value) {
      case 'string':
        return new Date(value).toLocaleTimeString(LOCALE, {
          ...options,
        })
      default:
        return value
    }
  },
  toISOString: () => {
    switch (typeof value) {
      case 'string':
        return new Date(value).toISOString().slice(0, 10)
      default:
        return value
    }
  },
})

export const formatter = (value) => formats(value)

export default formatter

// ---------------------------------------------------------------------

export const parseDay = (day, pattern) => {
  const date = new Date(day)

  const dateOnly = new Date(
    date.valueOf() + date.getTimezoneOffset() * 60 * 1000
  )

  return format(dateOnly, pattern || 'dd/MM')
}

import React from 'react'
import { useDispatch } from 'react-redux'
import logoEmpresa from 'assets/images/logo-empresa-white.png'
import * as medias from 'consts/medias'
import { company } from 'consts/types'
import formatter from 'services/formatter'
import useProfile from 'hooks/useProfile'
import { translate } from '_i18n'
import { signOutRequest } from 'store/modules/auth/reducer'
import { showFormEdit } from 'store/modules/admin/users/reducer'

import { Badge, useMediaQuery } from '@material-ui/core'
import { Signout as SignoutIcon } from '../Icons'
import MenuItems from '../MenuItems'
import ChangeLanguage from '../ChangeLanguage'
import UserFormEdit from '../User/FormEdit'

import {
  Container,
  CompanyLogo,
  ToolBar,
  Menu,
  Constrols,
  NotificationIcon,
  Profile,
  Avatar,
  ProfileInfo,
  SignOut,
  CompanyName,
} from './styles'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

export const portalTitles = {
  [company.ECOSIS]: 'Ecosis',
  [company.ADMIN]: 'Administração',
  [company.TRANSPORTADORA]: 'Transportadoras',
  [company.CLIENTE]: 'Clientes',
  [company.REPRESENTANTE]: 'Representantes',
}

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

export function SignoutButton(props) {
  const dispatch = useDispatch()
  const signOut = () => dispatch(signOutRequest())

  return (
    <SignOut {...props} onClick={signOut}>
      <SignoutIcon />
      <span>{translate('actions.signout')}</span>
    </SignOut>
  )
}

function Logo() {
  return (
    <CompanyLogo>
      <img alt="Embramaco" src={logoEmpresa} />
    </CompanyLogo>
  )
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function AppBar() {
  const lg = useMediaQuery(medias.lg)

  const dispatch = useDispatch()
  const profile = useProfile()

  const getTitle = () => portalTitles[profile.web_company.wee_tipo]
  const title = getTitle()

  const handleDisplayUserForm = () => {
    const webUser = {
      ...profile.web_user,
      id: profile.web_user.weu_id,
    }

    dispatch(showFormEdit(webUser))
  }

  return (
    <Container>
      <Logo title={title} />

      <ToolBar>
        <Menu>
          <MenuItems />
        </Menu>

        <Constrols>
          <Badge badgeContent={3} overlap="circular" color="error">
            <NotificationIcon />
          </Badge>
          <ChangeLanguage />
        </Constrols>

        <Profile>
          <Avatar
            src={profile.web_user.weu_avatar}
            onClick={handleDisplayUserForm}
          />
          <ProfileInfo>
            <span title={profile.web_user.weu_nome}>
              {profile.web_user.weu_nome}
            </span>
            <span>{profile.web_user.weu_email}</span>
          </ProfileInfo>
          <SignoutButton />
        </Profile>
      </ToolBar>

      {lg && (
        <CompanyName>
          {`(${formatter(profile.web_company.cod_empresa).toCNPJorCPF()}) `}
          {profile.company.emp_razao_social}
        </CompanyName>
      )}
      <UserFormEdit loggedUser={profile} />
    </Container>
  )
}

AppBar.propTypes = {}

export default AppBar

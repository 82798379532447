import styled from 'styled-components'
import { Button } from 'components/Button'

export const TableHeaderContainer = styled.div`
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
  }

  label {
    font-weight: bold;
    margin-right: 0.25rem;
  }
`

export const PrintButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.info.main};
`

import React, { useState, useMemo } from 'react'
import theme from 'theme'
import * as medias from 'consts/medias'
import { translate } from '_i18n'
import formatter from 'services/formatter'
import { createCollectTableData } from 'services/collects'
import { Box, useMediaQuery } from '@material-ui/core'
import { Edit } from 'components/Icons'
import { RenderTableRow, StyledCheckBox } from 'components/Table'
import {
  GroupContainer,
  GroupHeader,
  GroupBody,
} from 'components/Table/TableGroup'
import { Container } from './styles'

import FormEdit from './FormEdit'
import MultiSelectionTable from 'components/MultiSelectionTable'
import ChipStatus from 'components/ChipStatus'

function TableCollect({ data: collects, getRef, onUpdate, ...props }) {
  const sm = useMediaQuery(medias.sm)
  const [selected, setSelected] = useState(null)
  const isEditing = Boolean(selected)

  const tableData = createCollectTableData(collects)

  const summary = useMemo(() => {
    return collects.reduce(
      (acc, collect) => {
        acc.totalPallets += Number(collect.vpt_pallet)
        acc.totalAmount += Number(collect.vpt_qtd)
        acc.totalBoxes += Number(collect.vpt_qtd_caixas)
        acc.totalWeight += Number(collect.vpt_peso_bruto)
        return acc
      },
      {
        totalPallets: 0,
        totalAmount: 0,
        totalBoxes: 0,
        totalWeight: 0,
      }
    )
  }, [collects])

  function CellPallets({ rowData: order, cellValue, isGroupRow }) {
    const isEditable = order.vpt_pallet > 1
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {!isGroupRow && isEditable && (
          <Box className="hoverable" marginRight="5px">
            <Edit onClick={() => setSelected(order)} />
          </Box>
        )}
        <span>{cellValue}</span>
      </Box>
    )
  }

  function CellSchedule({ cellValue: schedule }) {
    const currentDate = new Date()
    const isImmediate = new Date(schedule) <= new Date(currentDate)

    return schedule ? (
      !isImmediate ? (
        <ChipStatus
          label={isImmediate ? 'Imediata' : formatter(schedule).toSimpleDate()}
          background={theme.palette.warning.main}
        />
      ) : (
        'Imediata'
      )
    ) : null
  }

  return (
    <Container {...props}>
      <MultiSelectionTable
        groupBy="cod_pedido"
        data={tableData}
        getRef={getRef}
        onUpdate={onUpdate}
        isAvailable={() => true}
        columns={({ onSelectRow }) => [
          {
            title: translate('orders.table.order'),
            field: 'cod_pedido',
            renderCell: ({ rowData: order }) => (
              <StyledCheckBox
                value={order.selected}
                checked={order.selected}
                onChange={onSelectRow(order)}
              />
            ),
          },
          {
            title: translate('orders.table.product'),
            field: 'dsc_abreviado',
          },
          {
            title: translate('orders.table.quantity'),
            field: 'vpt_qtd',
            align: 'right',
            cellFormat: 'decimal',
          },
          {
            title: translate('orders.table.weight'),
            field: 'vpt_peso_bruto',
            align: 'right',
            cellFormat: 'decimal',
          },
          {
            title: translate('orders.table.pallets'),
            field: 'vpt_pallet',
            align: 'right',
            renderCell: CellPallets,
          },
          {
            title: translate('orders.table.boxes'),
            field: 'vpt_qtd_caixas',
            align: 'right',
          },
          {
            title: translate('orders.table.delivery'),
            field: 'vpt_data_prev_entrega',
            renderCell: CellSchedule,
          },
          // {
          //   title: translate('orders.table.freight'),
          //   field: 'ped_tipo_frete',
          // },
          {
            title: translate('orders.table.representative'),
            field: 'rep_nome',
          },
          {
            title: 'DOC.CHAVE',
            field: 'docchave',
          },
          // {
          //   title: translate('orders.table.scheduling'),
          //   field: 'id_agendamento',
          //   renderCell: ({ cellValue: scheduleId }) =>
          //     scheduleId !== '0' ? <span>{scheduleId}</span> : null,
          // },
        ]}
        components={({
          onSelectTable,
          onSelectGroup,
          getTableChecked,
          getGroupChecked,
        }) => ({
          Header: () => {
            const firstCollect = collects[0]
            const checked = getTableChecked()

            return (
              <GroupContainer>
                <StyledCheckBox checked={checked} onChange={onSelectTable} />
                <div>
                  <GroupHeader>
                    <h3 className="group__title">
                      {firstCollect?.cliente_nome}
                    </h3>
                    <span className="group__info">{firstCollect?.dsc_uf}</span>
                  </GroupHeader>
                  <GroupBody>
                    <span>{firstCollect?.endereco_cliente}</span>
                    <span>{firstCollect?.cli_cidade}</span>
                    <span>
                      {formatter(firstCollect?.cod_cliente).toCNPJorCPF()}
                    </span>
                  </GroupBody>
                </div>
              </GroupContainer>
            )
          },

          Group: ({ rowData: collect, ...props }) => {
            const date = formatter(collect?.ped_data_emissao).toSimpleDate()
            const checked = getGroupChecked(collect)

            return (
              <RenderTableRow
                {...props}
                rowData={{
                  dsc_abreviado: sm ? date : `Emitido em ${date}`,
                  cod_pedido: () => (
                    <span>
                      <StyledCheckBox
                        checked={checked}
                        value={checked}
                        onChange={onSelectGroup(collect)}
                        disabled={!collect}
                      />
                      {collect.cod_pedido}
                    </span>
                  ),
                }}
              />
            )
          },

          Footer: (props) => {
            return (
              <RenderTableRow
                {...props}
                rowData={{
                  cod_pedido: 'TOTAL',
                  vpt_pallet: summary.totalPallets,
                  vpt_qtd: summary.totalAmount,
                  vpt_qtd_caixas: summary.totalBoxes,
                  vpt_peso_bruto: summary.totalWeight,
                }}
              />
            )
          },
        })}
      />
      {isEditing && (
        <FormEdit
          current={selected}
          open={isEditing}
          onClose={() => setSelected(null)}
        />
      )}
    </Container>
  )
}

export default TableCollect

import { all, call, put, takeLatest } from 'redux-saga/effects'

import apiEndPoints from '@/consts/apiEndPoints'
import api from '@/services/api'
import handleErrors from '@/services/handleErrors'

import { getVehiclesRequest, getVehiclesReply } from './reducer'

function* getVehicles() {
  try {
    const response = yield call(api.get, apiEndPoints.vehicles.root)

    const vehicles = response.data

    yield put(getVehiclesReply({ vehicles }))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar os usuários')
    yield put(getVehiclesReply())
  }
}

export default all([takeLatest(getVehiclesRequest.type, getVehicles)])

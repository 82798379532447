import { Paper } from '@/components'
import styled from 'styled-components'

export const VehiclesPaper = styled(Paper)`
  border: 0;
  max-width: 1200px;
  margin: 0 auto;

  .MuiTableCell-root {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .MuiTableCell-root:first-child {
    width: 5%;
  }
`

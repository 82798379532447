import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  dates: [],
  //
  // minDate: null,
  // originalMinDate: null,
  message: '',
}

const datesSlicer = createSlice({
  name: 'dates',
  initialState,
  reducers: {
    getPossibleDatesRequest(state) {
      state.loading = true
    },
    getPossibleDatesSuccess(state, { payload }) {
      const { message, dates } = payload

      state.loading = false
      state.dates = dates

      state.message = message
      // state.minDate = minDate
      // state.originalMinDate = originalMinDate
    },
    getPossibleDatesFailure(state) {
      state.loading = false
    },
  },
})

export const {
  getPossibleDatesFailure,
  getPossibleDatesRequest,
  getPossibleDatesSuccess,
} = datesSlicer.actions

export default datesSlicer.reducer

import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { ListDashes } from 'phosphor-react'
import { TableCell, TableRow } from './styles'

export function TableNoData(props) {
  const { empty, emptyText, colSpan, showEmptyContent } = props

  if (!empty) return null

  return (
    <TableRow>
      <TableCell align="center" colSpan={colSpan}>
        <Box
          height="69vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          {showEmptyContent && (
            <Box>
              <ListDashes size={60} />

              <Typography variant="body1" style={{ marginTop: '1rem' }}>
                {emptyText || 'Nenhum registro encontrado'}
              </Typography>
            </Box>
          )}
        </Box>
      </TableCell>
    </TableRow>
  )
}

TableNoData.propTypes = {
  emptyText: PropTypes.string,
  empty: PropTypes.bool,
  showEmptyContent: PropTypes.bool,

  colSpan: PropTypes.number,
}

TableNoData.defaultProps = {
  showEmptyContent: true,
}

import { useState, useCallback } from 'react'
import { extractLens } from 'services/utils'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

/**
 * @typedef {Object} SortDataProps
 * @property {string} initialField - Initial field to sort by
 * @property {'asc' | 'desc' | undefined } initialOrder - Initial order to sort by
 *
 * @typedef {Object} SortDataResult
 * @property {Object} currentSort - Current sort
 * @property {string} currentSort.field - Current field to sort by
 * @property {'asc' | 'desc'} currentSort.order - Current order to sort by
 * @property {(e: any, newSortField: string) => void} onSortChange - Function to change sort
 * @property {() => unknown[]} sortData - Function to sort data
 *
 * */

export const ORDER_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
}

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const getDescendingComparatorValue = (lens, value) => {
  const extracted = extractLens(lens, value)
  return extracted ? String(extracted).trim() : extracted
}

const descendingComparator = (right, left, orderBy, groupBy) => {
  let rightValue = getDescendingComparatorValue(orderBy, right)
  let leftValue = getDescendingComparatorValue(orderBy, left)

  if (groupBy) {
    rightValue = getDescendingComparatorValue(groupBy, right)
    leftValue = getDescendingComparatorValue(groupBy, left)
  }

  if (Number.isSafeInteger(parseInt(rightValue))) {
    return rightValue - leftValue
  }

  if (rightValue < leftValue) return -1

  if (rightValue > leftValue) return 1

  return 0
}

const getComparator = (order, orderBy, groupBy) => {
  return order === ORDER_TYPES.ASC
    ? (a, b) => descendingComparator(a, b, orderBy, groupBy)
    : (a, b) => -descendingComparator(a, b, orderBy, groupBy)
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])

    if (order !== 0) return order

    return a[1] - b[1]
  })

  return stabilizedThis.map((el) => el[0])
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------
/**
 * @description Hook to sort data
 * @type {(options: SortDataProps) => SortDataResult}
 */
export function useSortData({
  initialField,
  initialOrder = ORDER_TYPES.ASC,
} = {}) {
  const [currentSort, setCurrentSort] = useState({
    field: initialField,
    order: initialOrder,
  })

  const onSortChange = (_, newSortField) => {
    const isAsc =
      currentSort.field === newSortField &&
      currentSort.order === ORDER_TYPES.ASC

    setCurrentSort({
      field: newSortField,
      order: isAsc ? ORDER_TYPES.DESC : ORDER_TYPES.ASC,
    })
  }

  const sortData = useCallback(
    (records, groupBy) => {
      return stableSort(
        records,
        getComparator(currentSort.order, currentSort.field, groupBy)
      )
    },
    [currentSort]
  )

  return { currentSort, onSortChange, sortData }
}

export default useSortData

import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { translate } from '@/_i18n'
import { invoicesRequest } from '@/store/modules/users/queries/invoices/reducer'

import { SearchFilter, SideBar } from '@/components'
import { FormDatePicker } from '@/components/CoForm'
import { formatISO } from 'date-fns'

//-----------------------------------------------------------------------------

const initialData = {
  issued_from: formatISO(new Date()),
  issued_until: formatISO(new Date()),
  //
  id_coleta: '',
  vpt_placa: '',
  vpt_motorista: '',
  //
  nota_fiscal: '',
}

export function InvoiceFilter() {
  const loading = useSelector((state) => state.users.queries.invoices.loading)
  const dispatch = useDispatch()

  const getInvoices = useCallback(
    (filters) => {
      dispatch(invoicesRequest(filters))
    },
    [dispatch]
  )

  function handleSubmit(values) {
    getInvoices(values)
  }

  function handleClear() {
    getInvoices(initialData)
  }

  return (
    <SideBar
      ContentComponent={
        <SearchFilter
          inline={false}
          initialData={initialData}
          onSubmit={loading ? undefined : handleSubmit}
          onClear={handleClear}
          title={translate('actions.searchBy')}
          buttonLabel={translate('actions.send')}
          fields={[
            {
              name: 'issued_from',
              label: 'Emitido de',
              variant: 'inline',
              disableToolbar: true,
              disableFuture: true,
              Component: FormDatePicker,
            },
            {
              name: 'issued_until',
              label: 'Até',
              variant: 'inline',
              disableToolbar: true,
              disableFuture: true,
              Component: FormDatePicker,
              gutterBottom: true,
            },
            { name: 'id_coleta', label: 'Carga' },
            {
              name: 'vpt_placa',
              label: 'Placa',
            },
            {
              name: 'vpt_motorista',
              label: 'Motorista',
              gutterBottom: true,
            },
            {
              name: 'nota_fiscal',
              label: 'Nota',
            },
          ]}
        />
      }
    />
  )
}

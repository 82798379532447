import React, { useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { TableContainer, Tooltip, alpha } from '@material-ui/core'
import { groupWith } from 'ramda'

import theme, { colors } from '@/theme'
import { createQueryParameters } from '@/services/utils'
import { createCollectForPrint } from '@/services/collects'
import { formatter } from '@/services/formatter'

import { TableBody, TableCell, TableHead, TableRow } from '@/components/Table'
import { Table } from '@/components/Table/styles'
import { ChipStatus } from '@/components/ChipStatus'
import { TablePagination } from '@/components/TablePagination'
import { usePagination } from '@/hooks/usePagination'
import { issuedsRequest } from '@/store/modules/users/collections/reducer'

import { IssuedCollectsTableHeader } from './Header'
import { TableNoData } from '@/components/Table/TableNoData'
import { TableSpinner } from '@/components/Table'

export function IssuedCollectsTable({
  onPrintCollect,
  requestFilters,
  collectStatus,
}) {
  const count = useSelector((state) => state.users.collections.issueds.count)
  const data = useSelector((state) => state.users.collections.issueds.issueds)
  const loading = useSelector((state) => state.users.collections.loading)

  const dispatch = useDispatch()

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage, reset } =
    usePagination({
      initialRowsPerpage: 10,
    })

  useEffect(() => {
    // Join filters
    const filters = requestFilters.reduce((acc, item) => {
      const itemValue = item.value?.trim()

      if (acc[item.filterBy]) {
        acc[item.filterBy].push(itemValue)
      } else {
        acc[item.filterBy] = [itemValue]
      }

      return acc
    }, {})

    const queryParameters = createQueryParameters({
      page,
      rowsPerPage,
      filters,
      collect_status: collectStatus,
    })

    dispatch(issuedsRequest(queryParameters))
  }, [dispatch, page, rowsPerPage, requestFilters, collectStatus])

  useEffect(() => {
    reset()
  }, [reset, collectStatus])

  function handlePrint(collectId) {
    onPrintCollect(createCollectForPrint(collectId, data))
  }

  const renderShippingOrder = useCallback((coletaItem) => {
    if (!coletaItem.ford_numero) return null

    if (coletaItem.vpt_data_cancelamento) {
      return (
        <Tooltip
          arrow
          title={coletaItem.vpt_motivo_cancelamento}
          placement="top-start"
        >
          <div>
            <ChipStatus
              size="small"
              label={coletaItem.ford_numero}
              background={theme.palette.error.main}
            />
          </div>
        </Tooltip>
      )
    }

    return (
      <ChipStatus
        size="small"
        label={coletaItem.ford_numero}
        background={theme.palette.info.main}
      />
    )
  }, [])

  const getCellStyle = (coletaItem) => {
    if (coletaItem.vpt_data_cancelamento) {
      return {
        background: alpha(theme.palette.error.main, 0.05),
      }
    }
  }

  const coletas = useMemo(() => {
    /**
     * @type {unknown[][]}
     */
    const coletas = groupWith(groupById, data)

    return coletas.map((coletaItems) => ({
      header: coletaItems[0],
      items: coletaItems,
    }))
  }, [data])

  const noData = coletas.length === 0

  return (
    <TableContainer>
      {loading && <TableSpinner />}

      <Table size="medium">
        {coletas.map((coleta) => (
          <React.Fragment key={String(coleta.header.id)}>
            <TableBody>
              <TableRow>
                <TableCell colSpan={12}>
                  <IssuedCollectsTableHeader
                    data={coleta}
                    onPrint={handlePrint}
                  />
                </TableCell>
              </TableRow>
            </TableBody>

            <TableHead style={{ display: 'table-row-group' }}>
              <TableRow>
                <TableCell>COLETA</TableCell>
                <TableCell>PEDIDO</TableCell>
                <TableCell>DOC.CHAVE</TableCell>
                <TableCell>CLIENTE</TableCell>
                <TableCell>CIDADE</TableCell>
                <TableCell>UF</TableCell>
                <TableCell>PRODUTO</TableCell>
                <TableCell align="right">QUANTIDADE</TableCell>
                <TableCell align="right">PESO</TableCell>
                <TableCell align="right">PALLETS</TableCell>
                <TableCell align="right">CAIXAS</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody
              style={{
                borderBottom: `1px solid ${colors.color200}`,
              }}
            >
              {coleta.items.map((item) => {
                return (
                  <TableRow key={String(item.id)} style={getCellStyle(item)}>
                    <TableCell>{item.id_coleta}</TableCell>
                    <TableCell>{item.cod_pedido}</TableCell>
                    <TableCell>{item.docchave}</TableCell>
                    <TableCell>{item.cliente_nome}</TableCell>
                    <TableCell>{item.cidade_cliente}</TableCell>
                    <TableCell>{item.dsc_uf}</TableCell>
                    <TableCell>{item.dsc_abreviado}</TableCell>
                    <TableCell align="right">
                      {formatter(item.vpt_qtd).toDecimal()}
                    </TableCell>
                    <TableCell align="right">
                      {formatter(item.vpt_peso_bruto).toDecimal()}
                    </TableCell>
                    <TableCell align="right">{item.vpt_pallet}</TableCell>
                    <TableCell align="right">{item.vpt_qtd_caixas}</TableCell>
                    <TableCell align="right">
                      {renderShippingOrder(item)}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </React.Fragment>
        ))}

        {noData && (
          <TableBody>
            <TableNoData
              emptyText="Nenhuma coleta encontrada"
              empty={noData}
              showEmptyContent={!loading}
            />
          </TableBody>
        )}
      </Table>

      <TablePagination
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </TableContainer>
  )
}

IssuedCollectsTable.propTypes = {
  onPrintCollect: PropTypes.func,
}

function groupById(a, b) {
  return a.id_coleta_for_order === b.id_coleta_for_order
}

import styled from 'styled-components'
import MuiDivider from '@material-ui/core/Divider'
import Dialog from 'components/Dialog'

export const Divider = styled(MuiDivider)`
  margin: 5px 0;
`

export const StyledDialog = styled(Dialog)`
  .MuiDialogTitle-root,
  .MuiDialogActions-root {
    padding: 0;
  }
  .MuiDialogContent-root {
    padding: 10px 12px;
    form {
      border: none;
    }
  }
`

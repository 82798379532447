import { combineReducers } from 'redux'

import companies from './companies/reducer'
import users from './users/reducer'
import settings from './settings/reducer'
import vehicles from './vehicles/reducer'

export default combineReducers({
  companies,
  users,
  settings,
  vehicles,
})

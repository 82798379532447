export const email = {
  get withoutUnicode() {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  },
  get withUnicode() {
    return /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
  },
}

export const placa = {
  get array() {
    return [/[A-Z]/i, /[A-Z]/i, /[A-Z]/i, /\d/, /[A-Z\d]/i, /\d/, /\d/]
  },
  get regex() {
    return /[A-Z][A-Z][A-Z]-\d[A-Z\d]\d\d/
  },
}

export const cnpj = {
  get array() {
    return [
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ]
  },
  get regex() {
    return /\d\d.\d\d\d.\d\d\d\/\d\d\d\d-\d\d/
  },
}

export const cpf = {
  get array() {
    return [
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ]
  },
  get regex() {
    return /\d\d\d.\d\d\d.\d\d\d-\d\d/
  },
}

export const telefoneFixo = {
  get array() {
    return [
      '(',
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]
  },
}

export const telefoneCelular = {
  get array() {
    return [
      '(',
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]
  },
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import apiEndPoints from 'consts/apiEndPoints'
import api from 'services/api'
import handleErrors from 'services/handleErrors'
import { isEmail } from 'services/utils'
import Client from 'services/eventEmitter'

import { toast } from 'react-toastify'
import { FormCombobox, useCurrentForm } from 'components/CoForm'

import { makeStyles } from '@material-ui/styles'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import formatter from 'services/formatter'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const typeLabel = {
  carrier: 'Transportadora',
  customer: 'Cliente',
  representative: 'Representante',
}

const client = Client

const events = {
  fetchUserCompanies: '@portalEcosis/COMBOBOXUSERCOMPANIES/fetchUserCompanies',
}

export const comboBoxUserCompanies = {
  fetchUserCompanies(email) {
    client.emit(events.fetchUserCompanies, email)
  },
}

const useStyles = makeStyles((theme) => ({
  ToggleButton: {
    '&.MuiToggleButton-root.Mui-selected': {
      backgroundColor: '#A89A57',
      color: theme.palette.common.white,
    },
  },
}))
// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function ComboBoxUserCompanies({ name, ...props }) {
  const [userCompanies, setUserCompanies] = useState([])
  const [userTypes, setUserTypes] = useState([])
  const [loading, setLoading] = useState(false)
  const classes = useStyles(props)
  const { setFieldValue, getFieldValue } = useCurrentForm()

  const toogleType = getFieldValue('weu_id')
  const company = getFieldValue(name)

  async function fetchUserCompanies(email) {
    if (isEmail(email)) {
      setLoading(true)
      try {
        const response = await api.get(apiEndPoints.userCompanies, {
          params: { weu_email: email },
        })

        const users = [
          ...new Map(
            response.data.map((item) => [item['cod_empresa'], item])
          ).values(),
        ].map((user) => ({
          value: user.cod_empresa,
          label: `${user.emp_razao_social} (${formatter(
            user.cod_empresa
          ).toCNPJorCPF()})`,
        }))

        const types = response.data.map((user) => ({
          value: user.weu_id,
          label: user.wee_tipo,
          id: user.cod_empresa,
        }))

        if (isEmpty(response.data))
          toast.info('Não existem usuários cadastrados para esse e-mail')

        setFieldValue(name, users[0].value) // Select the first user company
        setFieldValue('weu_id', types[0].value)

        setUserCompanies(users)
        setUserTypes(types)
      } catch (error) {
        handleErrors(error, 'Não foi possível buscar as empresas')
        setUserCompanies([])
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    client.on(events.fetchUserCompanies, fetchUserCompanies)

    return () => {
      client.removeEventListener(events.fetchUserCompanies, fetchUserCompanies)
    }
  }, [])

  function handleTypeChange(e, newValue) {
    if (newValue !== null) {
      setFieldValue('weu_id', newValue)
    }
  }

  function handleCompanyChange(e, company) {
    setFieldValue(
      'weu_id',
      userTypes.filter((type) => type.id === company.value)[0].value
    )
  }

  return (
    <>
      <FormCombobox
        label="Escolha a empresa"
        variant="standard"
        options={userCompanies}
        loading={loading}
        disabled={loading}
        name={name}
        onAfterChange={handleCompanyChange}
        {...props}
      />
      {userTypes.length > 0 && (
        <ToggleButtonGroup
          color="primary"
          value={toogleType}
          exclusive
          onChange={handleTypeChange}
        >
          {userTypes
            .filter((item) => item.id === company)
            .map((userType, index) => (
              <ToggleButton
                size="small"
                classes={{ selected: classes.ToggleButton }}
                style={{
                  display:
                    userTypes.filter((item) => item.id === company).length === 1
                      ? 'none'
                      : 'block',
                }}
                key={index}
                value={userType.value}
              >
                <span>{typeLabel[userType.label]}</span>
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
      )}
    </>
  )
}

ComboBoxUserCompanies.propTypes = {
  // email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default ComboBoxUserCompanies

export const apiEndPoints = {
  signin: '/session',
  userCompanies: '/user_companies',

  admin: {
    dailyLimits: '/admin/daily_limits',
    webControl: '/admin/web_control',
  },

  user: {
    carrierDashboard: '/dashboard/home_carrier',
    ordersForCollect: '/orders_for_collect',
    ordersForSchedule: '/orders_for_schedule',
    collects: {
      root: '/collects',
      get destroySelected() {
        return `${this.root}/destroy_selected`
      },
      get count() {
        return `${this.root}/collect_count`
      },
      get dateCheck() {
        return `${this.root}/date_check`
      },
      onMember(id) {
        return `${this.root}/${id}`
      },
    },
    schedules: {
      root: '/schedules',
      get destroySelected() {
        return `${this.root}/destroy_selected`
      },
      get count() {
        return `${this.root}/schedule_count`
      },
      onMember(id) {
        return `${this.root}/${id}`
      },
    },
    orderCollects: {
      root: '/order_collects',
      get cancel() {
        return `${this.root}/cancel`
      },
      get reopen() {
        return `${this.root}/reopen`
      },
      get updateParams() {
        return `${this.root}/update_params`
      },
    },
    orderSchedules: {
      root: '/order_schedules',
      get cancel() {
        return `${this.root}/cancel`
      },
      get reopen() {
        return `${this.root}/reopen`
      },
      get updateParams() {
        return `${this.root}/update_params`
      },
    },
    shippingOrders: {
      root: '/shipping_orders',
    },
    // Orders
    orders: {
      root: '/orders',
      onMember(id) {
        return `${this.root}/${id}`
      },
      print(orderId) {
        return `${this.root}/${orderId}/order_print`
      },
      sendIssuedOrderEmail(orderId) {
        return `${this.root}/${orderId}/send_order_email`
      },
      items: {
        root: '/web_order_items',
        onMember(id) {
          return `${this.root}/${id}`
        },
      },
    },
  },

  passwords: {
    root: '/passwords',
    onMember(id) {
      return `${this.root}/${id}`
    },
    get updatePassword() {
      return `${this.root}/update_password`
    },
    get changePassword() {
      return `${this.root}/change_password`
    },
  },

  interestProducts: {
    root: '/interest_products',
  },

  miscellaneous: {
    root: '/miscellaneous',
    get provider() {
      return `${this.root}/provider`
    },
    get possibleDates() {
      return `${this.root}/possible_dates`
    },
    vehicle(placa) {
      return `${this.root}/vehicle?brd_placa=${placa}`
    },
    get truckTypes() {
      return `${this.root}/truck_type`
    },
    get carriers() {
      return `${this.root}/carriers`
    },
    get customers() {
      return `${this.root}/simplified_customers`
    },
    get lastVehicles() {
      return `${this.root}/last_vehicles`
    },
    get lastDrivers() {
      return `${this.root}/last_drivers`
    },
    bulletins(status) {
      return `/bulletins/?status=${status}`
    },
    bulletinAttachments(bulletinId) {
      return `/bulletins/${bulletinId}/attachments`
    },
    readBulletin(bulletinId) {
      return `/bulletins/${bulletinId}/read`
    },
    get unreadedBulletins() {
      return `/bulletins/?status=1`
    },

    get formats() {
      return `${this.root}/product_format`
    },
    get unitOfMeasures() {
      return `${this.root}/product_unit_of_measure`
    },
    brands(customerId) {
      return `${this.root}/brands/${customerId}`
    },
    get surfaces() {
      return `${this.root}/surfaces`
    },
    get lines() {
      return `${this.root}/lines`
    },
    get customerProducts() {
      return `${this.root}/customer_products`
    },
    get productReleases() {
      return `${this.root}/product_releases`
    },
    deliveryAddresses(customerId) {
      return `customers/${customerId}/delivery_addresses`
    },
    get cancelReasons() {
      return `${this.root}/cancel_reasons`
    },
    get profiles() {
      return `${this.root}/profiles`
    },
    get collectStatus() {
      return `${this.root}/collect_status`
    },
  },

  invites: {
    root: '/invites',
    onMember(id) {
      return `${this.root}/${id}`
    },
    accept(id) {
      return `${this.onMember(id)}/accept`
    },
  },

  webCompanies: {
    root: '/web_companies',
    onMember(id) {
      return `${this.root}/${id}`
    },
    sendWebToken(id) {
      return `${this.onMember(id)}/resend_web_token`
    },
  },

  webUsers: {
    root: '/web_users',
    onMember(id) {
      return `${this.root}/${id}`
    },
    get changeUser() {
      return `${this.root}/change_user`
    },
  },

  availableDates: {
    root: '/available_dates',
  },

  vehicles: {
    root: '/truck_types',
  },

  queries: {
    shipments: {
      root: '/weighings',
      onMember(id) {
        return `${this.root}/${id}`
      },
    },
    invoices: {
      root: '/invoices',
      onMember(id) {
        return `${this.root}/${id}`
      },
    },
    tradeReceivables: {
      root: '/trade_receivables',
    },
    bankSlip: {
      root: '/bank_slip',
      onMember(id) {
        return `${this.root}/${id}`
      },
    },
    inventory: 'reports/inventory',
    product_data_sheet(id) {
      return `reports/product_data_sheet/${id}`
    },
    issuedOrders: 'reports/issued_orders',
    billedOrders: 'reports/billed_orders',
    walletOrders: 'reports/portfolio_orders',
  },
}

export default apiEndPoints

import React, { useState, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Yup as y } from '@/services/yup'

import { Button, confirmationDialog } from '@/components'
import { Form, FormTextField } from '@/components/CoForm'
import { Typography, Grid, Box, Divider } from '@material-ui/core'
import {
  applyNumberMask,
  numberTextMaskProps,
  removeNumberMask,
} from '@/services/masks'

// ---------------------------------------------

const DailyLimitsSchema = y.object().shape({
  limite_quantidade_fracionada_diaria: y
    .string()
    .test(
      'value',
      'O valor deve ser maior que 0',
      (val) => val && removeNumberMask(val) > 0
    )
    .required('Informe o limite'),
})

// ---------------------------------------------

export function DailyLimits({ dailyLimits, onUpdate }) {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const decimalMask = numberTextMaskProps()

  const formRef = useRef(null)

  const defaultValues = useMemo(() => {
    return Object.entries(dailyLimits || {}).reduce((acc, [name, value]) => {
      acc[name] = applyNumberMask(value)
      return acc
    }, {})
  }, [dailyLimits])

  const handleSubmit = async (data) => {
    const confirm = await confirmationDialog.open(
      'Confirmar',
      'Confirma alteração dos limites diários?'
    )

    confirmationDialog.close()

    if (confirm) {
      setIsSubmitting(true)

      await onUpdate({
        limite_quantidade_fracionada_diaria: removeNumberMask(
          data.limite_quantidade_fracionada_diaria
        ),
      })

      setIsSubmitting(false)
    }
  }

  return (
    <Form
      formRef={formRef}
      initialData={defaultValues}
      schema={DailyLimitsSchema}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Diário</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            Quantidade Fracionada
          </Typography>

          <FormTextField
            fullWidth
            name="limite_quantidade_fracionada_diaria"
            textMaskProps={decimalMask}
            InputProps={{
              endAdornment: <Typography color="textSecondary">m²</Typography>,
            }}
          />
        </Grid>
      </Grid>

      <Box marginTop="1rem">
        <Divider style={{ margin: '1rem 0' }} />

        <Button
          loading={isSubmitting}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          size="large"
        >
          Salvar
        </Button>
      </Box>
    </Form>
  )
}

DailyLimits.propTypes = {
  dailyLimits: PropTypes.object,
  onUpdate: PropTypes.func,
}

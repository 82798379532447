import { all, takeLatest, call, put, select } from 'redux-saga/effects'

import apiEndPoints from 'consts/apiEndPoints'
import api from 'services/api'
import handleErrors from 'services/handleErrors'

import { invoicesRequest, invoicesSuccess, invoicesFailure } from './reducer'

function* fetchInvoices({ payload }) {
  try {
    
    const searchParams = yield select(
      (state) => state.users.queries.invoices.searchParams
    )
    
    const queryParameters = {
      params: payload,
      ...searchParams
    }

    const response = yield call(
      api.get,
      apiEndPoints.queries.invoices.root,
      queryParameters
    )

    yield put(
      invoicesSuccess({
        invoices: response.data,
        count: Number(response.headers.total),
      })
    )
  } catch (error) {
    yield put(invoicesFailure())
    handleErrors(error, 'Não foi possível realizar a consulta')
  }
}

export default all([takeLatest(invoicesRequest.type, fetchInvoices)])

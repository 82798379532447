import React from 'react'
import PropTypes from 'prop-types'
import { equals, isEmpty, merge, sum } from 'ramda'

import { translate } from '@/_i18n'
import * as medias from '@/consts/medias'
import formatter from '@/services/formatter'
import { createGroupHeader } from '@/services/utils'

import {
  createTableData,
  createSummary,
  isAvailable,
  isEmptyItem,
  inCollect,
  createCellStyle,
} from './utils'

import { Box, Tooltip, useMediaQuery, Typography } from '@material-ui/core'

import { StyledCheckBox } from '@/components/Table'
import { confirmationDialog } from '@/components/ConfirmationDialog'
import { TableHeader } from '@/components/Table/TableHeader'
import TableRow from '@/components/Table/TableRow'
import MultiSelectionTable from '@/components/MultiSelectionTable'

import { Container, TruckIcon, ChipArea, StyledChip } from './styles'

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function CollectIcon({ orderItem }) {
  switch (orderItem.em_coleta) {
    case '1':
      return <TruckIcon />
    case '2':
      return (
        <Tooltip
          arrow
          title={orderItem.em_coleta_usuario}
          placement="right-end"
        >
          <TruckIcon className="emColetaUsuario" />
        </Tooltip>
      )

    default:
      return null
  }
}

const outletContentMessage = (withUnavailableOutlets) => (
  <>
    <Typography component="p" variant="body1">
      Atenção, caso não inclua o item(s), posteriormente ele poderá ser recebido
      em tonalidade diferente.
    </Typography>
    {withUnavailableOutlets && (
      <Typography component="p" variant="body2">
        - Existe item(s) sem saldo, posteriormente ele poderá ser recebido em
        tonalidade diferente.
      </Typography>
    )}
  </>
)

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function TableOrders({
  data,
  onUpdate,
  getRef,
  getHeaderData,
  ...props
}) {
  const sm = useMediaQuery(medias.sm)
  const print = useMediaQuery(medias.print)

  const summary = createSummary(data)

  const createColumns = (methods) => {
    const { onSelectRow } = methods

    const renderOrderSelection = ({ rowData: orderItem }) => {
      if (!orderItem.cod_pedido) return null

      const checked = orderItem.selected
      const outletId = orderItem.outletId

      const handleSelectOrderItem = async (_, checked) => {
        async function handleUnselectedOutlets() {
          if (checked) {
            const isUnselectedOutlet = (item) =>
              equals(item.outletId, outletId) && !item.selected

            const unselectedOutlets = methods.filterBy(isUnselectedOutlet)
            if (unselectedOutlets.length === 1) return

            const unavailableOutlets = unselectedOutlets.filter(
              (i) => isEmptyItem(i) && !inCollect(i)
            )
            const withUnavailableOutlets = !isEmpty(unavailableOutlets)

            const tilte = `Incluir montagem de carga ${orderItem.dsc_abreviado} ?`
            const message = outletContentMessage(withUnavailableOutlets)

            if (await confirmationDialog.open(tilte, message))
              methods.select(unselectedOutlets.filter(isAvailable), checked)

            confirmationDialog.close()
          }
        }

        onSelectRow(orderItem)(_, checked)
        await handleUnselectedOutlets()
      }

      return (
        <Box display="flex" alignItems="center">
          {isAvailable(orderItem) && (
            <StyledCheckBox
              checked={checked}
              value={checked}
              onChange={handleSelectOrderItem}
            />
          )}
          <CollectIcon orderItem={orderItem} />
        </Box>
      )
    }

    let columns = [
      {
        title: translate('orders.table.order'),
        field: 'cod_pedido',
        renderCell: renderOrderSelection,
      },
      {
        title: translate('orders.table.product'),
        field: 'dsc_abreviado',
      },
      {
        title: translate('orders.table.quantity'),
        field: 'qtd_saldo_disp',
        align: 'right',
        cellFormat: 'decimal',
      },
      {
        title: translate('orders.table.weight'),
        field: 'peso_bru_disp',
        align: 'right',
        cellFormat: 'decimal',
      },
      {
        title: translate('orders.table.pallets'),
        field: 'qtd_pallet_disp',
        align: 'right',
      },
      {
        title: translate('orders.table.boxes'),
        field: 'qtd_caixas_disp',
        align: 'right',
      },
      {
        title: translate('orders.table.representative'),
        field: 'rep_nome',
      },
      {
        title: translate('orders.table.quantity'),
        field: 'qtd_saldo_pedido',
        align: 'right',
        cellFormat: 'decimal',
      },
      {
        title: translate('orders.table.weight'),
        field: 'peso_bru_pedido',
        align: 'right',
        cellFormat: 'decimal',
      },
      {
        title: translate('orders.table.pallets'),
        field: 'qtd_pallet_pedido',
        align: 'right',
      },
      {
        title: translate('orders.table.boxes'),
        field: 'qtd_caixas_pedido',
        align: 'right',
      },
      {
        title: 'DOC.CHAVE',
        field: 'docchave',
        align: 'left',
      },
    ]

    if (print) {
      const commonFields = [
        'cod_pedido',
        'ped_tipo_frete',
        'rep_nome',
        'data_entrega',
        'docchave',
      ]
      columns = columns.filter((c) => !commonFields.includes(c.field))
    }

    return columns
  }

  const createComponents = (provided) => {
    let components = {}

    const {
      onSelectTable,
      getTableChecked,
      getGroupChecked,
      onSelectGroup,
      getShowGroupSelector,
    } = provided

    const Header = () => {
      let checkboxProps = null
      const showCheckbox = getShowGroupSelector()
      const helperText = [summary.emp_telefone, summary.emp_telefone2]
        .filter((text) => text?.trim()?.length > 0)
        .join(' / ')
      const bodyData = [
        summary.cli_endereco_princ,
        summary.cli_cidade_princ,
        formatter(summary.cod_cliente).toCNPJorCPF(),
      ]

      if (showCheckbox) {
        const checked = getTableChecked()
        checkboxProps = {
          checked,
          onChange: onSelectTable,
        }
      }

      // ================
      // ==== HEADER ====
      // ================

      const tableHeaderDefaultProps = {
        title: summary.cli_nome,
        helperText,
        bodyData,
      }

      const tableHeaderProps = getHeaderData
        ? merge(tableHeaderDefaultProps, getHeaderData(summary))
        : tableHeaderDefaultProps

      return <TableHeader checkboxProps={checkboxProps} {...tableHeaderProps} />
    }

    const Group = ({ rowData: orderItem, ...props }) => {
      const columns = props.columns
      const checked = getGroupChecked(orderItem)

      const groupColumns = [
        { field: 'order' },
        { field: 'date', colSpan: print ? 0 : columns.length - 1 },
      ]

      const helperRows = [
        { label: 'Entregar em', text: orderItem.cli_endereco },
        {
          label: 'Obs',
          text: orderItem.ped_obs,
          className: 'Chip-obs',
        },
      ]

      if (print)
        groupColumns.push({
          field: 'order_descr',
          colSpan: columns.length - 2,
        })

      return (
        <>
          <TableRow
            {...props}
            columns={groupColumns}
            rowData={{
              ...orderItem,
              date: sm ? orderItem.ped_data_emissao : orderItem.issued_at,
              order_descr: [
                orderItem.ped_tipo_frete,
                orderItem.rep_nome,
                orderItem.data_entrega,
              ].join('/'),
              order: (
                <span>
                  {getShowGroupSelector(orderItem) && (
                    <StyledCheckBox
                      checked={checked}
                      value={checked}
                      onChange={onSelectGroup(orderItem)}
                      disabled={!orderItem}
                    />
                  )}
                  {orderItem.cod_pedido}
                </span>
              ),
            }}
          />
          {helperRows.map(({ label, text, className }, index) => {
            let helperColumns = []
            helperColumns[0] = columns[0]
            helperColumns[1] = {
              ...columns[1],
              colSpan: 15,
            }

            return text ? (
              <TableRow
                {...props}
                key={index}
                columns={helperColumns}
                isGroupRow={false}
                rowData={{
                  dsc_abreviado: (
                    <ChipArea>
                      <StyledChip className={className} label={label} />
                      <span>{text}</span>
                    </ChipArea>
                  ),
                }}
              />
            ) : null
          })}
        </>
      )
    }

    const Footer = (props) => {
      const tableData = provided.tableData
      const sumOf = (field) => sum([...tableData.map((d) => d[field])])

      return (
        <TableRow
          {...props}
          rowData={{
            cod_pedido: 'TOTAL',
            qtd_saldo_disp: sumOf('qtd_saldo_disp'),
            peso_bru_disp: sumOf('peso_bru_disp'),
            qtd_pallet_disp: sumOf('qtd_pallet_disp'),
            qtd_caixas_disp: sumOf('qtd_caixas_disp'),
            qtd_saldo_pedido: sumOf('qtd_saldo_pedido'),
            peso_bru_pedido: sumOf('peso_bru_pedido'),
            qtd_pallet_pedido: sumOf('qtd_pallet_pedido'),
            qtd_caixas_pedido: sumOf('qtd_caixas_pedido'),
          }}
        />
      )
    }

    components.Header = Header
    components.Group = Group
    components.Footer = Footer
    return components
  }

  return (
    <Container {...props}>
      <MultiSelectionTable
        groupBy="cod_pedido"
        data={data}
        columns={createColumns}
        components={createComponents}
        isAvailable={isAvailable}
        TableProps={{
          groupHeader: print
            ? [createGroupHeader('DISPONÍVEL/PEDIDO', 3, 8)]
            : [
                createGroupHeader('DISPONÍVEL', 3, 4),
                createGroupHeader('PEDIDO', 2, 4),
              ],
          cellStyle: createCellStyle,
        }}
        getRef={getRef}
        onUpdate={onUpdate}
        onRefresh={createTableData}
      />
    </Container>
  )
}

TableOrders.propTypes = {
  getHeaderData: PropTypes.func,
}

export default TableOrders

import { inviteStatus } from '@/consts/types'

export const applyScope = (scope, selector, collection) =>
  collection.filter((member) =>
    scope !== '' ? selector(member) === scope : true
  )

export const applyScopeInvite = (scope, selector, collection) =>
  collection.filter((member) => {
    const status = selector(member)

    if (scope === inviteStatus.ALL) return true

    return scope === inviteStatus.INVITED
      ? [inviteStatus.ACCEPTED, inviteStatus.PENDING].includes(status) // invited
      : status === scope // not_invited
  })

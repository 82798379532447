import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PATHS from 'consts/paths'
import { Yup } from 'services/yup'
import { recoveryRequest } from 'store/modules/auth/reducer'

import {
  Container,
  StyledForm,
  SubmitButton,
  StyledLink as Link,
} from '../styles'

import { FormTextField } from 'components/CoForm'
import {
  ComboBoxUserCompanies,
  comboBoxUserCompanies,
} from 'components/Combobox/UserCompanies'

import logoEmpresa from '../../../assets/images/logo-empresa.svg'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const initialFormData = {
  weu_email: '',
}

const schema = Yup.object().shape({
  weu_email: Yup.string().email().required('Email deve ser informado'),
  company: Yup.string().required('Selecione uma empresa para continuar'),
})

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function ResetPasswordPage() {
  const formRef = useRef(null)

  const dispatch = useDispatch()
  const loading = useSelector((state) => state.auth.loading)

  function handleBlur(e) {
    const email = e.target.value
    comboBoxUserCompanies.fetchUserCompanies(email)
  }

  function handleSubmit(values) {
    dispatch(recoveryRequest(values))
  }

  return (
    <Container>
      <StyledForm
        onSubmit={handleSubmit}
        initialData={initialFormData}
        formRef={formRef}
        schema={schema}
      >
        <main>
          <header>
            <img src={logoEmpresa} alt="Embramaco - Cerâmica" />
            <span className="subtitle">
              Informe seu email para solicitar uma nova senha.
            </span>
          </header>
          <div className="fields">
            <FormTextField
              autoFocus
              name="weu_email"
              variant="standard"
              size="medium"
              label="Email"
              type="email"
              onBlur={handleBlur}
            />
            <ComboBoxUserCompanies name="company" />
            <SubmitButton
              color="primary"
              variant="contained"
              disableElevation
              type="submit"
              label="Enviar"
              loading={loading}
            />
            <div className="links">
              <Link to={PATHS.signin.link}>Entrar</Link>
            </div>
          </div>
        </main>
      </StyledForm>
    </Container>
  )
}

export default ResetPasswordPage

import { useState, useCallback } from 'react'

export default function useListState(initialData = []) {
  const [list, setList] = useState(initialData)

  const removeItem = useCallback((itemId) => {
    setList((previousList) => {
      return previousList.filter((item) => item.id !== itemId)
    })
  }, [])

  const updateItem = useCallback((itemId, data) => {
    setList((previousList) => {
      return previousList.map((item) =>
        item.id !== itemId ? item : Object.assign({ ...item }, { ...data })
      )
    })
  }, [])

  const addItem = useCallback((newItem) => {
    setList((previousList) => {
      return [...previousList, newItem]
    })
  }, [])

  return {
    list,
    setList,
    //
    removeItem,
    updateItem,
    addItem,
  }
}

import styled, { css } from 'styled-components'
import { colors } from '@/theme'

import Paper from '@/components/Paper'
import BaseCard from '@/components/Card'

const cardStyles = {
  dark: css`
    min-width: 300px;
    background-color: ${colors.color200};

    > div:first-child {
      background-color: ${colors.color700};
      border-radius: 8px 0 7px 0;

      svg {
        color: ${colors.white};
      }
    }

    > div:nth-child(2) {
      .title {
        color: #000;
      }
    }
  `,
}

export const DashboardContainer = styled(Paper)`
  border: none;
  min-height: 76vh;
  padding: 2rem 1rem;
`

export const Card = styled(BaseCard)`
  width: 100%;
  min-width: 300px;
  padding-bottom: 1rem;

  ${(props) => cardStyles[props.variant] || ''};
`

export const InfoText = styled.div`
  color: ${colors.color500};
  font-size: 36px;
  text-align: end;
`

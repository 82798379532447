import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { updateCollectItemRequest } from 'store/modules/users/collections/reducer'
import Dialog from 'components/Dialog'
import Button from 'components/Button'
import { FormTextField } from 'components/CoForm'
import { StyledForm } from './styles'

function CollectRowFormEdit({ current, onClose, ...props }) {
  const formRef = useRef(null)
  const dispatch = useDispatch()

  function handleSave(updateParams) {
    dispatch(updateCollectItemRequest({ current, updateParams }))
    onClose()
  }

  return (
    <Dialog
      title="Alterar a Qtdade. de Pallets"
      fullScreen={false}
      onClose={onClose}
      actions={
        <>
          <Button
            label="Cancelar"
            variant="outlined"
            color="primary"
            onClick={onClose}
          />
          <Button
            label="Salvar"
            color="primary"
            onClick={() => handleSave(formRef.current.values)}
          />
        </>
      }
      {...props}
    >
      <StyledForm onSubmit={handleSave} formRef={formRef} initialData={current}>
        <FormTextField
          fullWidth
          label="Pallets"
          type="number"
          name="vpt_pallet"
          variant="standard"
          inputProps={{
            min: 1,
            max: Number(current.vpt_pallet),
          }}
        />
      </StyledForm>
    </Dialog>
  )
}

CollectRowFormEdit.propTypes = {
  current: PropTypes.object,
}

export default CollectRowFormEdit

import { darken } from 'polished'

const themeKey = '@EmbramacoPortal:theme'

export function saveProfileTheme(profileCompanyType) {
  localStorage.setItem(themeKey, profileCompanyType)
}

export function getProfileTheme() {
  return {
    main: '#E1E7EE',
    get color100() {
      return "#cbcfd5"
    },
    get color200() {
      return darken(0.2, this.main)
    },
    get color300() {
      return darken(0.3, this.main)
    },
    get color400() {
      return darken(0.4, this.main)
    },
    get color500() {
      return darken(0.45, this.main)
    },
    get color600() {
      return darken(0.6, this.main)
    },
    get color700() {
      return "#4b525c"
    },
    get color800() {
      return darken(0.8, this.main)
    },
    get color900() {
      return darken(0.9, this.main)
    },
    get colorAppBar(){
      return '#024990'
    }
  }
}

import styled from 'styled-components'
import Paper from '../Paper'

export const DialogMessageContainer = styled(Paper)`
  padding: 20px 0;
  > div {
    font-size: 16px;
    line-height: 150%;
  }

  li {
    margin: 5px 16px;
    list-style: initial;
  }
`

import styled from 'styled-components'
import * as medias from '@/consts/medias'

export const SplashScreenContainer = styled.div`
  position: fixed;
  z-index: 2;

  top: 50%;
  left: 50vw;

  @media ${medias.md} {
    left: 40vw;
  }
`

import React from 'react'
import { Print as PrinCollect, Schedule } from '@material-ui/icons'
import { Box, Divider, Typography } from '@material-ui/core'

import formatter from '@/services/formatter'

import { ChipStatus } from '@/components/ChipStatus'
import { TableHeaderContainer, PrintButton } from './styles'

export function IssuedCollectsTableHeader({ data: collect, onPrint }) {
  const collectHeader = collect.header

  const cancelObservation = `${formatter(
    collectHeader.vpt_data_cancelamento
  ).toSimpleDate()} - ${collectHeader.vpt_motivo_cancelamento}`

  const disablePrint = collectHeader.vpt_data_cancelamento !== null

  return (
    <TableHeaderContainer>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h3">{collectHeader.id_coleta}</Typography>

        <Box gridGap="1rem" display="flex">
          <Typography component="span" variant="body2">
            <label>EMISSÂO:</label>
            {formatter(collectHeader.vpt_data_emissao).toSimpleDate()}
          </Typography>

          <Typography component="span" variant="body2">
            <label>COLETA: </label>
            {formatter(collectHeader.vpt_dcoleta).toSimpleDate()}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" gridGap="1rem">
        <Typography component="span">
          {formatter(collectHeader.vpt_cod_motorista).toCPF()}
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography component="span">{collectHeader.vpt_motorista}</Typography>
        <Divider orientation="vertical" flexItem />
        <Typography component="span">{collectHeader.vpt_placa}</Typography>
      </Box>

      <Box>
        <Typography component="p" variant="body2">
          {collectHeader.vpt_obs}
        </Typography>

        {collectHeader.vpt_data_cancelamento && (
          <Typography component="p" variant="body2" color="error">
            {cancelObservation}
          </Typography>
        )}
      </Box>

      <Box display="flex" justifyContent="space-between" marginTop="0.5rem">
        <PrintButton
          size="small"
          label="Imprimir"
          startIcon={<PrinCollect />}
          onClick={() => onPrint(collectHeader.id_coleta)}
          variant="contained"
          color="primary"
          disableElevation={false}
          disabled={disablePrint}
        />

        {collectHeader.status && (
          <ChipStatus
            icon={<Schedule color="inherit" fontSize="small" />}
            label={collectHeader.status.descr}
            background={collectHeader.status.color}
            className="large"
          />
        )}
      </Box>
    </TableHeaderContainer>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

import { Box, CircularProgress, Typography } from '@material-ui/core'
import { SplashScreenContainer } from './styles'

export function SplashScreen(props) {
  return (
    <SplashScreenContainer style={props.style}>
      <Box
        zIndex={'3'}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <CircularProgress />
        <Typography color="textSecondary">{props.loadingText}</Typography>
      </Box>
    </SplashScreenContainer>
  )
}

SplashScreen.propTypes = {
  loadingText: PropTypes.string,
  loaderSize: PropTypes.number,
}

export default SplashScreen

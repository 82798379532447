import formatter from 'services/formatter'
import { today } from 'services/utils'

export const getItemId = (item) => [
  item.weu_id,
  item.cod_pedido,
  item.id_cia,
  item.id_itens,
  item.id_coleta,
]

export const isEmptyOrderItem = (item) => Number(item.peso_bru) === 0

export function createCollectTableData(collectItems) {
  return collectItems.map((item) => {
    return {
      ...item,
      rowId: item.id,
      selected: false,
      withError: false,
    }
  })
}

export function createCollectItemForPrint(item) {
  return {
    id_coleta: item.id_coleta,
    id_cia: item.id_cia,
    cia_descricao_carreg: item.cia_descricao_carreg,
    cod_cliente: item.cod_cliente,
    cod_pedido: item.cod_pedido,
    cod_produto: item.cod_produto,
    dsc_produto: item.dsc_abreviado,
    vpt_peso_bruto: item.vpt_peso_bruto,
    vpt_qtd: item.vpt_qtd,
    vpt_qtd_caixas: item.vpt_qtd_caixas,
    vpt_pallet: item.vpt_pallet,
    vpt_data_prev_entrega: item.vpt_data_prev_entrega,
    ford_numero: item.ford_numero,
    ped_representante: item.rep_nome,
    cli_cidade: item.cli_cidade,
    dsc_uf: item.dsc_uf,
    customer: {
      emp_razao_social: item.cliente_nome,
      emp_endereco: item.endereco_cliente,
    },
  }
}

export function createCollectForPrint(idColeta, collects) {
  const coletasSelecionadas = collects.filter((ci) => ci.id_coleta === idColeta)

  const coleta = coletasSelecionadas[0]

  const coletaItems = coletasSelecionadas
    .filter((ci) => ci.vpt_data_cancelamento === null)
    .map(createCollectItemForPrint)

  return {
    id: idColeta,
    items: coletaItems,
    obs: coleta.vpt_obs,
    date: formatter(coleta.vpt_data_emissao).toDate(),
    prevDate: formatter(coleta.vpt_dcoleta).toSimpleDate(),
    printDate: today(),
    lastUpdateDate: coleta.vpt_dinsert
      ? formatter(coleta.vpt_dinsert).toDate()
      : null,
    //
    driver: {
      id: coleta.vpt_cod_motorista,
      name: coleta.vpt_motorista,
    },
    truck: {
      plate: coleta.vpt_placa,
      dsc_caminhao: coleta.truck_type?.dsc_tipo_caminhao,
      dsc_tipo_caminhao: coleta.truck_type?.dsc_tipo_caminhao,
    },
    //
    status: coleta.status,
    schedules: coleta.schedules,
  }
}

import { Paper } from '@/components'
import theme from '@/theme'
import { Button } from '@material-ui/core'
import styled from 'styled-components'

export const InvoicesTableContainer = styled(Paper)`
  border: 0;
  max-width: 1200px;
  margin: 0 auto;

  .MuiTableCell-root {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .MuiTableCell-root:first-child {
    width: 5%;
  }

  .MuiTableRow-root:nth-child(odd) .MuiTableCell-body.MuiTableCell-root {
    border-bottom: 1px solid ${theme.palette.grey[100]};
  }
`

export const DownloadButton = styled(Button)`
  padding: 2px 6px;
  font-size: 0.75rem;
`

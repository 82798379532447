import React, { useCallback, useEffect } from 'react'
import { Bell } from 'phosphor-react'
import { useDispatch, useSelector } from 'react-redux'

import { PATHS } from '@/consts/paths'
import { unreadedBulletinsRequest } from '@/store/modules/comuns/bulletins/reducer'
import { MenuItem } from '../MenuItem'

// -----------------------------------------------------------------

const GET_BULLETINS_COUNT_INTERVAL = 1000 * 60 * 3 // 3mnts

// -----------------------------------------------------------------

export function CommonMenuItems() {
  const dispatch = useDispatch()

  const unreadedBulletins = useSelector(
    (state) => state.comuns.bulletins.unreadedBulletins
  )

  const fetchUnreadedBulletins = useCallback(
    () => dispatch(unreadedBulletinsRequest()),
    [dispatch]
  )

  useEffect(() => {
    fetchUnreadedBulletins()
  }, [fetchUnreadedBulletins])

  useEffect(() => {
    const unreadedBulletinsFetchInterval = setInterval(
      () => fetchUnreadedBulletins(),
      GET_BULLETINS_COUNT_INTERVAL
    )

    return () => clearInterval(unreadedBulletinsFetchInterval)
  }, [fetchUnreadedBulletins])

  return (
    <>
      <MenuItem
        Icon={Bell}
        label="Avisos"
        path={PATHS.common.bulletins}
        badgeContent={unreadedBulletins}
        badgePosition={{
          top: 6,
          right: 5,
          topactive: 6,
          rightactive: 6,
        }}
      />
    </>
  )
}

import { Box, Typography, useMediaQuery } from '@material-ui/core'
import {
  AccountCircle,
  Description,
  LocalShipping,
  Print,
} from '@material-ui/icons'
import { groupBy, prop } from 'ramda'
import React, { useMemo } from 'react'
import BarCode from 'react-barcode'

import { TableBody, TableCell, TableHead, TableRow } from '@/components/Table'
import logoColorido from 'assets/images/logo-empresa.svg'
import { Dialog } from 'components/index'
import * as medias from 'consts/medias'
import { useProfile } from 'hooks'
import formatter from 'services/formatter'
import { padZero } from 'services/utils'
import { colors } from 'theme'

import { Table, TableFooter } from '@/components/Table/styles'
import { unmask } from 'services/masks'
import {
  Cards,
  Container,
  GroupContainer,
  Header,
  StyleCardBody,
  StyledButton,
  StyledCard,
  StyledCardIcon,
} from './styles'

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function Card({ Icon, title, content, ...props }) {
  return (
    <StyledCard {...props}>
      <StyledCardIcon>
        <Icon fontSize="large" />
      </StyledCardIcon>
      <StyleCardBody>
        <Typography component="div" variant="body1">
          {title}
        </Typography>
        <Typography component="div" variant="body2">
          {content}
        </Typography>
      </StyleCardBody>
    </StyledCard>
  )
}

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

export function CollectPrint({ collect, ...props }) {
  const sm = useMediaQuery(medias.sm)
  const carrier = useProfile().company
  const encryptedId = padZero(10, collect.id)

  const summary = useMemo(() => {
    return collect.items.reduce(
      (acc, item) => {
        acc.weight += Number(item.vpt_peso_bruto)
        acc.amount += Number(item.vpt_qtd)
        acc.pallets += Number(item.vpt_pallet)
        acc.boxes += Number(item.vpt_qtd_caixas)

        return acc
      },
      {
        weight: 0,
        amount: 0,
        pallets: 0,
        boxes: 0,
      }
    )
  }, [collect])

  const schedulePasswords = collect.schedules
    .filter((s) => s.ford_senha)
    .map((s) => `${s.ford_senha} (${formatter(s.ford_data).toSimpleDate()})`)
    .join(', ')

  function handleInviteAndPrint() {
    window.print()
  }

  const groupItemsByCustomer = groupBy(prop('cod_cliente'))
  const itemsByCustomer = Object.values(groupItemsByCustomer(collect.items))

  return (
    <Dialog
      {...props}
      fullWidth
      fullScreen={sm}
      maxWidth="lg"
      title={`Impressão Coleta ${collect.id}`}
      className="printable"
      actions={
        <>
          <StyledButton
            color="primary"
            label="Fechar"
            variant="contained"
            onClick={props.onClose}
          />
          <StyledButton
            color="primary"
            startIcon={<Print />}
            label="Imprimir"
            variant="outlined"
            onClick={handleInviteAndPrint}
          />
        </>
      }
    >
      <Container>
        {/* === BARCODE HEADER ===  */}
        <Header>
          <div className="Header-barcode">
            <BarCode
              height={sm ? 30 : 100}
              value={encryptedId}
              displayValue={false}
            />
          </div>
          <div className="Header-content">
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Embramaco - Pedido de Coleta
            </Typography>

            {collect.status && (
              <Typography gutterBottom component="div" variant="body1">
                {collect.status.descr}
              </Typography>
            )}

            <Typography component="div" variant="body1">
              <b>Transportadora:</b>
              <span>{carrier.emp_razao_social}</span>
            </Typography>
            <Typography gutterBottom component="div" variant="body1">
              <b>Nº Coleta:</b>
              <span>{collect.id}</span>
            </Typography>
            <Box display="flex">
              <Typography component="div" variant="body1">
                <b>Emissão:</b>
                <span>{collect.date}</span>
              </Typography>
              {collect.lastUpdateDate && (
                <Typography
                  component="div"
                  variant="body1"
                  style={{ marginLeft: '12px' }}
                >
                  <b>Ultima Alteração:</b>
                  <span>{collect.lastUpdateDate}</span>
                </Typography>
              )}
            </Box>
            <Typography component="div" variant="body1">
              <b>Impressão:</b>
              <span>{collect.printDate}</span>
            </Typography>
          </div>
          <div className="Header-logo">
            <img src={logoColorido} alt="logo" />
          </div>
        </Header>

        {/* === CARDS ===  */}
        <Cards>
          <Card
            Icon={AccountCircle}
            title={collect.driver.name}
            content={
              <p>{formatter(unmask(collect.driver.id)).toCNPJorCPF()}</p>
            }
          />
          <Card
            Icon={LocalShipping}
            title={collect.truck.plate}
            content={<p>{collect.truck.dsc_caminhao}</p>}
          />
          <Card
            Icon={Description}
            title={`Data da Coleta: ${collect.prevDate}`}
            content={<p>{collect.obs}</p>}
          />
        </Cards>

        <Table size="medium">
          {itemsByCustomer.map((customers, index) => {
            const customer = customers[0].customer

            const customerStr = `${customer.emp_razao_social} (${formatter(
              customers[0].cod_cliente
            ).toCNPJorCPF()})`

            return (
              <React.Fragment key={index}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      colSpan={12}
                      style={{
                        backgroundColor: 'white',
                        padding: '1rem 0',
                      }}
                    >
                      <GroupContainer>
                        <Typography
                          variant="body2"
                          style={{ fontWeight: 'bold' }}
                        >
                          {customerStr}
                        </Typography>

                        <Typography
                          variant="body2"
                          component="span"
                          color="textSecondary"
                        >
                          {customer.emp_endereco}
                        </Typography>
                      </GroupContainer>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>PEDIDO</TableCell>
                    <TableCell>PRODUTO</TableCell>
                    <TableCell align="right">QUANTIDADE</TableCell>
                    <TableCell align="right">PESO</TableCell>
                    <TableCell align="right">PALLETS</TableCell>
                    <TableCell align="right">CAIXAS</TableCell>
                    <TableCell>REPRESENTANTE</TableCell>
                    <TableCell>CIDADE</TableCell>
                    <TableCell>UF</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody
                  className="TableBody-stripped"
                  style={{ borderBottom: `1px solid ${colors.color300}` }}
                >
                  {customers.map((customer, index) => (
                    <TableRow key={index}>
                      <TableCell>{customer.cod_pedido}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {customer.dsc_produto}
                      </TableCell>
                      <TableCell align="right">
                        {formatter(customer.vpt_qtd).toDecimal()}
                      </TableCell>
                      <TableCell align="right">
                        {formatter(customer.vpt_peso_bruto).toDecimal()}
                      </TableCell>
                      <TableCell align="right">{customer.vpt_pallet}</TableCell>
                      <TableCell align="right">
                        {customer.vpt_qtd_caixas}
                      </TableCell>
                      <TableCell>{customer.ped_representante}</TableCell>
                      <TableCell>{customer.cli_cidade}</TableCell>
                      <TableCell>{customer.dsc_uf}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </React.Fragment>
            )
          })}
          <TableFooter>
            <TableRow>
              <TableCell>TOTAL</TableCell>
              <TableCell colSpan={1} />
              <TableCell align="right">
                {formatter(summary.amount).toDecimal()}
              </TableCell>
              <TableCell align="right">
                {formatter(summary.weight).toDecimal()}
              </TableCell>
              <TableCell align="right">{summary.pallets}</TableCell>
              <TableCell align="right">{summary.boxes}</TableCell>
              <TableCell colSpan={3} />
            </TableRow>
          </TableFooter>
        </Table>

        {/* === SCHEDULES === */}
        {schedulePasswords.length > 0 && (
          <Box marginY={2}>
            <Typography color="primary" component="span" variant="subtitle2">
              <Box component="b" marginRight={1}>
                Agendamentos incluidos:
              </Box>
              <span>{schedulePasswords}</span>
            </Typography>
          </Box>
        )}
      </Container>
    </Dialog>
  )
}

export default CollectPrint

import React from 'react'
import { Tray, Gear, House, Truck, Users, Buildings } from 'phosphor-react'

import { PATHS } from '@/consts/paths'
import { Box, Divider, useMediaQuery } from '@material-ui/core'
import { MenuItem } from '../MenuItem'
import theme from '@/theme'

export function AdminMenuItems() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
      <MenuItem Icon={House} label="Home" path={PATHS.admin.home} />

      <MenuItem Icon={Tray} label="Avisos" path={PATHS.common.bulletins} />

      <MenuItem Icon={Truck} label="Veículos" path={PATHS.admin.vehicles} />

      <MenuItem
        Icon={Buildings}
        label="Empresas"
        path={PATHS.admin.companies}
      />

      <MenuItem Icon={Users} label="Usuários" path={PATHS.admin.users} />

      {isMobile ? (
        <MenuItem
          Icon={Gear}
          label="Preferências"
          path={PATHS.admin.settings}
        />
      ) : (
        <Box display="flex">
          <Divider
            flexItem
            orientation="vertical"
            style={{ marginLeft: '0.5rem', marginRight: '2rem' }}
          />

          <MenuItem
            Icon={Gear}
            label="Preferências"
            path={PATHS.admin.settings}
          />
        </Box>
      )}
    </Box>
  )
}
